import { FC, CSSProperties, useState, useCallback } from 'react';

import MuiInputBase from '@mui/material/InputBase';

import { createClassNames, cx } from '@@styles';
import { Measure } from '@@models';
import { addRdbMeasure, modifyRdbMeasureText, removeRdbMeasure } from '@@Rdb';
import { useOptionsCtxAbsolutely, useDomainCtxAbsolutely } from '@@ctx';
import { getDateTimeStr } from '@@utils';

const c = createClassNames({
    root: {},
    userName: {
        fontSize: 12,
    },
    createdAt: {
        fontSize: 12,
    },
    text: {
        '& textarea:focus': {
            outline: 'solid 1px #000',
        },
    },
    buttons: {},
    submitButton: {},
    cancelButton: {},
    removeButton: {
        color: '#f00',
    },
});

export const MeasureEditor: FC<{
    className?: string;
    style?: CSSProperties;
    measure: Measure | undefined;
    keywordText: string;
    onClose: () => void;
}> = ({ className, style, measure, keywordText, onClose }) => {
    // CTX
    const { userOption } = useOptionsCtxAbsolutely();
    const { domain } = useDomainCtxAbsolutely();

    // STATE
    const [text, setText] = useState<string | undefined>(measure?.text);

    // CALLBACK
    const submit = useCallback(() => {
        if (text) {
            if (measure) {
                modifyRdbMeasureText({ domain, id: measure.id, text });
            } else {
                addRdbMeasure({ domain, keywordText, text, userId: userOption.user.id });
            }
            onClose();
        }
    }, [domain, text, measure?.id, userOption.user.id, onClose, keywordText]);
    const remove = useCallback(() => {
        if (measure) {
            removeRdbMeasure({ domain, id: measure.id });
            onClose();
        }
    }, [domain, measure?.id, onClose]);

    return (
        <div
            className={cx(c.root, className)}
            style={{
                background: userOption.user.color.getCssValue({ opacity: 0.2 }),
                ...style,
            }}
        >
            <div>
                <span
                    className={c.userName}
                    style={{
                        color: userOption.user.color.getCssValue({ opacity: 1 }),
                        // background: userOption.user.color.getCssValue({ opacity: 0.3 }),
                    }}
                >
                    {userOption.user.uniqueName}
                </span>
                {!!measure && (
                    <span className={c.createdAt}>
                        {getDateTimeStr({
                            date: measure.createdAt,
                            isOnlyTimeIfToday: false,
                            isNoYearForThisYear: true,
                        })}
                    </span>
                )}
            </div>
            <MuiInputBase
                className={c.text}
                value={text || ''}
                onChange={(event) => {
                    setText(event.target.value || undefined);
                }}
                placeholder={'施策内容（改行可）'}
                multiline
                fullWidth
            />
            <div className={c.buttons}>
                <button onClick={onClose} className={c.cancelButton}>
                    {'キャンセル'}
                </button>
                <button onClick={submit} className={c.submitButton} disabled={!text || measure?.text === text}>
                    {'保存'}
                </button>
                {!!measure && (
                    <button onClick={remove} className={c.removeButton}>
                        {'削除'}
                    </button>
                )}
            </div>
        </div>
    );
};
MeasureEditor.displayName =
    '@@construction/WholePage/Authorized/KeywordsTable/ChunkRow/KeywordRow/MeasuresPart/MeasureEditor';
