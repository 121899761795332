import { getPresenceStates, listenToPresenceStates } from '@@Rdb';
import { DispatchPresenceStates } from '../useDomainData';

export const initPresenceStates = async ({
    domain,
    dispatchPresenceStates,
}: {
    domain: string;
    dispatchPresenceStates: DispatchPresenceStates;
}) => {
    const presenceStates = await getPresenceStates({ domain });
    dispatchPresenceStates({
        setPresenceStates: {
            update: () => presenceStates,
        },
    });

    const { unsubscribeListenToPresenceStates } = listenToPresenceStates({
        domain,
        onAdded: (addedPresenceState) => {
            dispatchPresenceStates({
                setPresenceState: {
                    key: addedPresenceState.userId,
                    update: () => addedPresenceState,
                },
            });
        },
        onChanged: (changedPresenceState) => {
            dispatchPresenceStates({
                setPresenceState: {
                    key: changedPresenceState.userId,
                    update: () => changedPresenceState,
                },
            });
        },
        onRemoved: (removedKey) => {
            dispatchPresenceStates({
                removePresenceState: {
                    key: removedKey,
                },
            });
        },
    });

    return unsubscribeListenToPresenceStates;
};
