import { FC, CSSProperties } from 'react';

import { createClassNames, createClassNamesGenerator, cx } from '@@styles';
import { NoteOption } from '@@models';

const c = createClassNames({
    root: {},
    userName: {},
    createdAt: {},
    text: {},
});

const f = createClassNamesGenerator({});

export const NoteCard: FC<{
    className?: string;
    style?: CSSProperties;
    noteOption: NoteOption;
}> = ({ className, style, noteOption }) => {
    // DATA
    const { note, userOption } = noteOption;

    return (
        <div
            className={cx(c.root, className)}
            style={{
                color: userOption.user.color.getCssValue({ opacity: 1 }),
                // background: userOption.user.color.getCssValue({ opacity: 0.3 }),
                ...style,
            }}
        >
            <span className={c.text}>{note.text}</span>
        </div>
    );
};
NoteCard.displayName = '@@construction/WholePage/Authorized/KeywordsTable/ChunkRow/KeywordRow/NotesPart/NoteCard';
