import { FC, CSSProperties, useState } from 'react';

import { createClassNames, cx } from '@@styles';
import { KeywordOption } from '@@models';

import { MeasureCard } from './MeasureCard';
import { MeasureEditor } from './MeasureEditor';

const c = createClassNames({
    root: {},
});

export const MeasuresPart: FC<{
    className?: string;
    style?: CSSProperties;
    keywordOption: KeywordOption;
}> = ({ className, style, keywordOption }) => {
    // STATE
    const [editingMeasureId, setEditingMeasureId] = useState<ID | 'new' | undefined>(undefined);

    // DATA
    const arr = keywordOption.measureOptions.getArrByCreatedAt();

    return (
        <div className={cx(c.root, className)} style={style}>
            {arr.map((measureOption) => {
                if (editingMeasureId === measureOption.measure.id) {
                    return (
                        <MeasureEditor
                            key={measureOption.measure.id}
                            measure={measureOption.measure}
                            keywordText={keywordOption.keyword.query}
                            onClose={() => setEditingMeasureId(undefined)}
                        />
                    );
                }
                return (
                    <MeasureCard
                        key={measureOption.measure.id}
                        measureOption={measureOption}
                        onClickEdit={() => setEditingMeasureId(measureOption.measure.id)}
                    />
                );
            })}
            {editingMeasureId === 'new' ? (
                <MeasureEditor
                    measure={undefined}
                    keywordText={keywordOption.keyword.query}
                    onClose={() => setEditingMeasureId(undefined)}
                />
            ) : (
                <button onClick={() => setEditingMeasureId('new')}>+</button>
            )}
        </div>
    );
};
MeasuresPart.displayName = '@@construction/WholePage/Authorized/KeywordsTable/ChunkRow/KeywordRow/MeasuresPart';
