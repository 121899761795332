import { createContext, useContext } from 'react';

import { Measures } from '@@models';

type MeasuresCtxType = {
    measures: Loading<Measures>;
};

export const MeasuresCtx = createContext<MeasuresCtxType>({
    measures: 'loading',
});

export const useMeasuresCtx = () => {
    return useContext(MeasuresCtx);
};

export const useMeasuresCtxAbsolutely = () => {
    const { measures } = useMeasuresCtx();
    if (measures === 'loading') {
        throw new Error('useMeasuresCtxAbsolutely: measures === loading.');
    }
    return {
        measures,
    };
};
