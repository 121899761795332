import { getPins, listenToPins } from '@@Rdb';
import { DispatchPins } from '../useDomainData';

export const initPins = async ({ domain, dispatchPins }: { domain: string; dispatchPins: DispatchPins }) => {
    const pins = await getPins({ domain });
    dispatchPins({
        setPins: {
            update: () => pins,
        },
    });

    const { unsubscribeListenToPins } = listenToPins({
        domain,
        onAdded: (addedPin, key) => {
            dispatchPins({
                setPin: {
                    key,
                    update: () => addedPin,
                },
            });
        },
        onChanged: (changedPin, key) => {
            dispatchPins({
                setPin: {
                    key,
                    update: () => changedPin,
                },
            });
        },
        onRemoved: (removedKey) => {
            dispatchPins({
                removePin: {
                    key: removedKey,
                },
            });
        },
    });

    return unsubscribeListenToPins;
};
