import { Note } from '../rawModels';

import { UserOption } from './UserOption';

export class NoteOption {
    // DATA
    readonly note: Note;
    readonly userOption: UserOption;

    constructor({
        note,
        userOption,
    }: {
        // DATA
        readonly note: Note;
        readonly userOption: UserOption;
    }) {
        this.note = note;
        this.userOption = userOption;
    }
}
