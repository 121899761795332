import { FC } from 'react';

import { signOut } from '@@firebase';

export const Dinied: FC = () => {
    return (
        <div>
            <div>access denied</div>
            <button onClick={() => signOut({ projectName: 'default' })}>sign out</button>
        </div>
    );
};
Dinied.displayName = '@@construction/WholePage/Dinied';
