import { createContext, useContext } from 'react';

import { NoteOptions, UserOptions, KeywordOptions, MeasureOptions, KeywordChunkOptions, UserOption } from '@@models';

export type OptionsCtxType = {
    noteOptions: Loading<NoteOptions>;
    userOptions: Loading<UserOptions>;
    keywordOptions: Loading<KeywordOptions>;
    measureOptions: Loading<MeasureOptions>;
    // keywordChunkOptions: Loading<KeywordChunkOptions>;
    userOption: Loading<UserOption | undefined>;
};

export const OptionsCtx = createContext<OptionsCtxType>({
    noteOptions: 'loading',
    userOptions: 'loading',
    keywordOptions: 'loading',
    measureOptions: 'loading',
    // keywordChunkOptions: 'loading',
    userOption: 'loading',
});

export const useOptionsCtx = () => {
    return useContext(OptionsCtx);
};

export const useOptionsCtxAbsolutely = () => {
    const {
        noteOptions,
        userOptions,
        keywordOptions,
        measureOptions,
        // keywordChunkOptions,
        userOption,
    } = useOptionsCtx();
    if (
        noteOptions === 'loading' ||
        userOptions === 'loading' ||
        keywordOptions === 'loading' ||
        measureOptions === 'loading' ||
        // keywordChunkOptions === 'loading' ||
        userOption === 'loading'
    ) {
        throw new Error('useOptionsCtxAbsolutely: loading.');
    }
    if (!userOption) {
        throw new Error('useOptionsCtxAbsolutely: !userOption.');
    }
    return {
        noteOptions,
        userOptions,
        keywordOptions,
        measureOptions,
        // keywordChunkOptions,
        userOption,
    };
};
