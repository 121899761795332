import { FC, useState } from 'react';

import { createClassNames, cx } from '@@styles';

import { TextEditor } from './TextEditor';

const c = createClassNames({
    root: {
        'fontSize': 13,
        'background': '#eee',
        'border': 'solid 1px #888',
        'padding': '0 7px',
        'cursor': 'default',
        'borderRadius': 2,
        'display': 'flex',
        'alignItems': 'center',
        '&:hover': {
            background: '#e6e6e6',
        },
        'minHeight': 23,
    },
    label: {},
    postText: {},
    text: {},
});

export const TextMenu: FC<{
    className?: string;
    label: string;
    text: string | undefined;
    displayPlaceholder?: string;
    editorPlaceholder?: string;
    onChange: (text: string | undefined) => void;
    postText?: string;
}> = ({ className, label, text, displayPlaceholder, editorPlaceholder, onChange, postText }) => {
    // STATE
    const [isEditing, setIsEditing] = useState(false);

    return (
        <div
            className={cx(c.root, className)}
            onClick={() => {
                if (!isEditing) {
                    setIsEditing(true);
                }
            }}
        >
            <span className={c.label}>
                {label}
                {':'}
            </span>
            {isEditing ? (
                <TextEditor
                    text={text || ''}
                    onClose={() => setIsEditing(false)}
                    placeholder={editorPlaceholder}
                    submit={(newText) => onChange(newText || undefined)}
                />
            ) : (
                <span className={c.text}>{text || displayPlaceholder}</span>
            )}
            {postText && <span className={c.postText}>{postText}</span>}
        </div>
    );
};
TextMenu.displayName = '@@construction/WholePage/Authorized/KeywordsTable/TopMenu/TextMenu';
