import { FC, CSSProperties } from 'react';

import { createClassNames, cx } from '@@styles';
import { KeywordOption, NoteOption, NoteOptions } from '@@models';
import { useOptionsCtxAbsolutely } from '@@ctx';

import { NoteCard } from './NoteCard';
import { NoteEditor } from './NoteEditor';

const c = createClassNames({
    root: {},
});

export const NotesPart: FC<{
    className?: string;
    style?: CSSProperties;
    keywordOption: KeywordOption;
}> = ({ className, style, keywordOption }) => {
    // CTX
    const { userOption } = useOptionsCtxAbsolutely();

    // DATA
    const { clientNoteOption, otherNoteOptions } = (() => {
        const { clientNoteOption, otherNoteOptionsData } = keywordOption.noteOptions.reduce(
            (acc, noteIdentifier, noteOption) => {
                if (noteOption.note.userId === userOption.user.id) {
                    return {
                        ...acc,
                        clientNoteOption: noteOption,
                    };
                }
                return {
                    ...acc,
                    otherNoteOptionsData: {
                        ...acc.otherNoteOptionsData,
                        [noteIdentifier]: noteOption,
                    },
                };
            },
            {
                clientNoteOption: undefined,
                otherNoteOptionsData: {},
            } as {
                clientNoteOption: NoteOption | undefined;
                otherNoteOptionsData: {
                    [noteIdentifier: string]: NoteOption;
                };
            }
        );
        return {
            clientNoteOption,
            otherNoteOptions: new NoteOptions(otherNoteOptionsData),
        };
    })();
    // console.log({ clientNoteOption, otherNoteOptions });

    return (
        <div className={cx(c.root, className)} style={style}>
            {otherNoteOptions.map((_, noteOption) => (
                <NoteCard key={noteOption.note.userId} noteOption={noteOption} />
            ))}
            <NoteEditor
                note={clientNoteOption?.note}
                keywordText={keywordOption.keyword.query}
                onClose={() => undefined}
            />
            {/* {isEditing ? (
                <NoteEditor
                    note={clientNoteOption?.note}
                    keywordText={keywordOption.keyword.query}
                    onClose={() => setIsEditing(false)}
                />
            ) : clientNoteOption ? (
                <NoteCard noteOption={clientNoteOption} />
            ) : (
                <></>
            )} */}
        </div>
    );
};
NotesPart.displayName = '@@construction/WholePage/Authorized/KeywordsTable/ChunkRow/KeywordRow/NotesPart';
