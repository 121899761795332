export const serializePinIdentifier = ({ keywordText, userId }: { keywordText: string; userId: string }): string => {
    return `${keywordText}::${userId}`;
};

export const parsePinIdentifier = ({
    pinIdentifier,
}: {
    pinIdentifier: string;
}): Errorable<{
    keywordText: string;
    userId: string;
}> => {
    const match = pinIdentifier.match(/^([^:]+)::(.+)$/);
    if (match) {
        const [_, keywordText, userId] = match;
        if (keywordText && userId) {
            return {
                keywordText,
                userId,
            };
        }
    }
    throw new Error(`parsePinIdentifier: failed to parse ${pinIdentifier}.`);
};
