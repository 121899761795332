import { ScQueryResults } from '@@models';

export const setScQueryResults = ({
    prev,
    update,
}: {
    prev: Loading<ScQueryResults>;
    update: (prev: Loading<ScQueryResults>) => ScQueryResults;
}): ScQueryResults => {
    const current = prev === 'loading' ? 'loading' : new ScQueryResults({ ...prev.data });
    return update(current);
};
