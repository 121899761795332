import { useReducer, useCallback } from 'react';

export type Unsubscribers = {
    [id: string]: () => void;
};
export type UnsubscriberForId = (input: { id: string }) => void;
export type SetUnsubscriberForId = (input: { id: string; unsubscriber: () => void }) => void;
export type UnsubscribeAll = () => void;

type DispatchUnsubscribersInput = {
    set?: {
        id: string;
        unsubscriber: () => void;
    };
    unsubscribe?: {
        id: string;
    };
    unsubscribeAll?: boolean;
};

export const useUnsubscribers = () => {
    const [unsubscribers, dispatchUnsubscribers] = useReducer<
        (state: Unsubscribers, input: DispatchUnsubscribersInput) => Unsubscribers
    >((state, { set, unsubscribe, unsubscribeAll }) => {
        if (set) {
            return {
                ...state,
                [set.id]: set.unsubscriber,
            };
        }
        if (unsubscribe) {
            const unsubscriber = state[unsubscribe.id];
            if (unsubscriber) {
                unsubscriber();
            }
            const newState = { ...state };
            delete newState[unsubscribe.id];
            return newState;
        }
        if (unsubscribeAll) {
            Object.keys(state).forEach((id) => {
                const unsubscriber = state[id]!;
                unsubscriber();
            });
            return {};
        }
        throw new Error('useUnsubscribers: Unexpected.');
    }, {});

    const unsubscribe = useCallback(({ id }: { id: string }) => {
        dispatchUnsubscribers({ unsubscribe: { id } });
    }, []);
    const setUnsubscriber = useCallback(({ id, unsubscriber }: { id: string; unsubscriber: () => void }) => {
        dispatchUnsubscribers({ set: { id, unsubscriber } });
    }, []);
    const unsubscribeAll = useCallback(() => {
        dispatchUnsubscribers({ unsubscribeAll: true });
    }, []);

    return {
        unsubscribe,
        setUnsubscriber,
        unsubscribeAll,
        unsubscribers,
    };
};
