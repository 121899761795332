import { FC, ReactNode, useEffect } from 'react';

import { FirebaseUser } from '@@firebase';
import { ScTerm } from '@@models';
import { keywordsOver100, keywordsUnder100 } from '@@inCodeData';
import { enterSpace } from '@@Rdb';

import { DomainCtx } from './DomainCtx';
import { KeywordsCtx } from './KeywordsCtx';
import { MeasuresCtx } from './MeasuresCtx';
import { NotesCtx } from './NotesCtx';
import { OptionsCtx } from './OptionsCtx';
import { PinsCtx } from './PinsCtx';
import { PresenceStatesCtx } from './PresenceStatesCtx';
import { ScQueryResultsCtx } from './ScQueryResultsCtx';
import { UsersCtx } from './UsersCtx';
import { useDomainData } from './useDomainData';
import { useInitDomainData } from './useInitDomainData';
import { computeOptions } from './computeOptions';

export const DomainDataProvider: FC<{
    term: ScTerm;
    firebaseUser: FirebaseUser;
    domain: string;
    children: ReactNode;
}> = ({ term, firebaseUser, domain, children }) => {
    // STATE
    const {
        measures,
        notes,
        pins,
        presenceStates,
        scQueryResults,
        users,
        dispatchMeasures,
        dispatchNotes,
        dispatchPins,
        dispatchPresenceStates,
        dispatchScQueryResults,
        dispatchUsers,
    } = useDomainData();
    const { initDomainData } = useInitDomainData({
        dispatchMeasures,
        dispatchNotes,
        dispatchPins,
        dispatchPresenceStates,
        dispatchScQueryResults,
        dispatchUsers,
    });

    // FX
    useEffect(() => {
        initDomainData({ domain });
        enterSpace({
            userId: firebaseUser.uid,
            domain,
            getWindowIsFocused: async () => true, // later
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [domain]);

    // DATA
    const options = computeOptions({
        firebaseUser,
        measures,
        notes,
        pins,
        users,
        presenceStates,
        scQueryResults,
        keywords: keywordsOver100,
        term,
    });

    // useEffect(() => {
    //     console.log('options:', options);
    // }, [options]);

    // console.log({ measures, notes, presenceStates, scQueryResults, users, options });

    return (
        <DomainCtx.Provider value={{ domain }}>
            <KeywordsCtx.Provider
                value={{
                    keywordsOver100,
                    keywordsUnder100,
                }}
            >
                <MeasuresCtx.Provider value={{ measures }}>
                    <NotesCtx.Provider value={{ notes }}>
                        <PinsCtx.Provider value={{ pins }}>
                            <PresenceStatesCtx.Provider value={{ presenceStates }}>
                                <ScQueryResultsCtx.Provider value={{ scQueryResults }}>
                                    <UsersCtx.Provider value={{ users }}>
                                        <OptionsCtx.Provider value={options}>{children}</OptionsCtx.Provider>
                                    </UsersCtx.Provider>
                                </ScQueryResultsCtx.Provider>
                            </PresenceStatesCtx.Provider>
                        </PinsCtx.Provider>
                    </NotesCtx.Provider>
                </MeasuresCtx.Provider>
            </KeywordsCtx.Provider>
        </DomainCtx.Provider>
    );
};
DomainDataProvider.displayName = 'DomainDataProvider';
