import { FC, useState } from 'react';

import { createClassNames } from '@@styles';
import { signOut } from '@@firebase';
import { useFirebaseUserCtxAbsolutely } from '@@ctx';
import { redirectToGoogle } from '@@google';

const c = createClassNames({
    button: {
        background: '#ccc',
        color: '#666',
        // position: 'fixed',
        // top: 0,
        // right: 0,
        // zIndex: 3,
        border: 'none',
        fontSize: 12,
        padding: '4px 8px',
        flexShrink: 0,
    },
    menu: {
        position: 'fixed',
        top: 0,
        right: 0,
        background: '#fff',
        boxShadow: '0 0 16px 0 #000',
        zIndex: 4,
        padding: 21,
    },
});

export const Menu: FC = () => {
    // CTX
    const { firebaseUser } = useFirebaseUserCtxAbsolutely();

    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <button className={c.button} onClick={() => setIsOpen(true)}>
                {'Menu'}
            </button>
            {isOpen && (
                <div className={c.menu}>
                    <button onClick={() => signOut({ projectName: 'default' })}>sign out</button>
                    {firebaseUser.uid === 'DhFIAvPR3iNLSwTDlOUpQgXHYnO2' && (
                        <button
                            onClick={() =>
                                redirectToGoogle({
                                    scopes: ['https://www.googleapis.com/auth/webmasters.readonly'],
                                })
                            }
                        >
                            get oauth
                        </button>
                    )}
                    <button onClick={() => setIsOpen(false)}>閉じる</button>
                </div>
            )}
        </>
    );
};
Menu.displayName = '@@construction/WholePage/Authorized/Menu';
