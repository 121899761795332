import { forwardRef, MouseEvent, ComponentProps } from 'react';
import MuiPopover from '@mui/material/Popover';

import { createClassNames, cx } from '@@styles';

export type { PopoverOrigin, PopoverPosition } from '@mui/material';

const c = createClassNames({
    paper: {
        backdropFilter: 'blur(40px)',
        background: 'rgba(0,0,0,.23)',
        boxShadow: '0 64px 64px -64px rgb(0 0 0 / 48%)',
        WebkitAppRegion: 'no-drag',
    },
});

export const Popover = forwardRef<
    HTMLDivElement,
    Omit<ComponentProps<typeof MuiPopover>, 'onClose' | 'classes' | 'className' | 'open' | 'transitionDuration'> & {
        className?: string;
        isOpen: boolean;
        onClose?: (mouseEvent: MouseEvent | undefined, reason: 'backdropClick' | 'escapeKeyDown') => void;
    }
>(({ className, isOpen, onClose, ...otherProps }, ref) => {
    return (
        <MuiPopover
            ref={ref}
            open={isOpen}
            onClose={(e, reason) => {
                if (onClose) {
                    const mouseEvent = e as MouseEvent; // later
                    onClose(mouseEvent, reason);
                }
            }}
            classes={{ paper: cx(c.paper, className) }}
            transitionDuration={{ enter: 300, exit: 200 }}
            TransitionProps={{ unmountOnExit: true }}
            {...otherProps}
        />
    );
});
Popover.displayName = 'Popover';
