import { Pins, Pin } from '@@models';

import { setPins } from './setPins';

export const setPin = ({
    prev,
    key,
    update,
}: {
    prev: Pins;
    key: string;
    update: (prev: Pin | undefined) => Pin;
}): Pins => {
    return setPins({
        prev,
        update: (prevPins) => {
            if (prevPins === 'loading') {
                throw new Error('setPin: prevPins === loading.');
            }
            prevPins.update(key, update);
            return prevPins;
        },
    });
};
