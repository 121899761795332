import { ref } from 'firebase/database';

import { FirebaseProjectName } from '../firebaseConfig';
import { getFirebaseRealtimeDatabase } from './getFirebaseRealtimeDatabase';

export const getConnectedRef = ({ projectName }: { projectName: FirebaseProjectName }) => {
    const db = getFirebaseRealtimeDatabase({ projectName });
    const connectedRef = ref(db, '.info/connected');
    return {
        connectedRef,
    };
};
