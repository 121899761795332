import { GoogleAuthProvider, signInWithRedirect } from 'firebase/auth';

import { FirebaseProjectName } from '../firebaseConfig';
import { getFirebaseAuth } from './getFirebaseAuth';

export const signinWithGoogle = async ({ projectName }: { projectName: FirebaseProjectName }) => {
    const provider = new GoogleAuthProvider();
    const auth = getFirebaseAuth({ projectName });
    await signInWithRedirect(auth, provider);
};
