import { useState } from 'react';

import type { FirebaseUser } from '@@firebase';

export const useFirebaseUser = () => {
    // STATE
    const [firebaseUser, setFirebaseUser] = useState<Loading<FirebaseUser | undefined>>('loading');

    return {
        firebaseUser,
        setFirebaseUser,
    };
};
