import { FC, useState } from 'react';

import { createClassNames } from '@@styles';
import { useOptionsCtx, SortBy, useLocationCtx } from '@@ctx';
import { KeywordOptions, ScTerm } from '@@models';

import { TopMenu } from './TopMenu';
import { TableHeader } from './TableHeader';
import { KeywordRow } from './KeywordRow';

const c = createClassNames({
    root: {
        // height: '100vh',
        // display: 'flex',
        overscrollBehaviorX: 'none',
        width: 'fit-content',
        height: '100%',
        // overflow: 'scroll',
        // flexGrow: 1,
        // flexShrink: 0,
        position: 'relative',
    },
    content: {
        marginTop: 50,
    },
    rowWithMeasures: {
        // opacity: 0.3,
        color: 'rgba(0,0,0,.3)',
    },
    withPinsSection: {
        borderBottom: 'solid 2px #ddd',
    },
    noMeasureSection: {
        borderBottom: 'solid 2px #ddd',
    },
});

const getKeywordOptionArr = ({
    keywordOptions,
    sortBy,
    term,
}: {
    keywordOptions: KeywordOptions;
    sortBy: SortBy;
    term: ScTerm;
}) => {
    if (sortBy === 'clicks') {
        return keywordOptions.getArrayByClicks({ term });
    }
    if (sortBy === 'impressions') {
        return keywordOptions.getArrayByImpressions({ term });
    }
    if (sortBy === 'ctr') {
        return keywordOptions.getArrayByCTR({ term });
    }
    if (sortBy === 'position') {
        return keywordOptions.getArrayByPosition({ term });
    }
    if (sortBy === 'score') {
        return keywordOptions.getArrayByScore();
    }
    return keywordOptions.getArrayByLosingVolume();
};

export const KeywordsTable: FC = () => {
    // CTX
    const { keywordOptions, userOption } = useOptionsCtx();
    const { term, filterByCategory, filterByVolume, filterByWord, sortBy } = useLocationCtx();

    // STATE
    const [expandedQuery, setExpandedQuery] = useState<string | undefined>(undefined);

    return (
        <div className={c.root}>
            <TopMenu />
            <div className={c.content}>
                <TableHeader />
                {keywordOptions === 'loading' || userOption === 'loading'
                    ? 'loading...'
                    : !userOption
                    ? 'Invalid User'
                    : (() => {
                          const { withPins, noMeasures, hasMeasures } = keywordOptions.filterBulk({
                              userId: userOption?.user.id,
                              measureDays: -7,
                              volume: filterByVolume
                                  ? {
                                        orOver: filterByVolume,
                                        term,
                                    }
                                  : undefined,
                              word: filterByWord,
                              category: filterByCategory,
                          });
                          const withPinsArr = getKeywordOptionArr({
                              keywordOptions: withPins,
                              sortBy,
                              term,
                          });
                          const noMeasuresArr = getKeywordOptionArr({
                              keywordOptions: noMeasures,
                              sortBy,
                              term,
                          });
                          const hasMeasuresArr = getKeywordOptionArr({
                              keywordOptions: hasMeasures,
                              sortBy,
                              term,
                          });
                          const isNoItem = withPinsArr.length + noMeasuresArr.length + hasMeasuresArr.length === 0;
                          return isNoItem ? (
                              <div>{'条件に該当する項目がありません'}</div>
                          ) : (
                              <div>
                                  <div className={c.withPinsSection}>
                                      {withPinsArr.map((keywordOption) => (
                                          <KeywordRow
                                              key={keywordOption.keyword.query}
                                              keywordOption={keywordOption}
                                              isExpanded={expandedQuery === keywordOption.keyword.query}
                                              onClickExpand={() =>
                                                  setExpandedQuery(
                                                      expandedQuery !== keywordOption.keyword.query
                                                          ? keywordOption.keyword.query
                                                          : undefined
                                                  )
                                              }
                                          />
                                      ))}
                                  </div>
                                  <div className={c.noMeasureSection}>
                                      {noMeasuresArr.map((keywordOption) => (
                                          <KeywordRow
                                              key={keywordOption.keyword.query}
                                              keywordOption={keywordOption}
                                              isExpanded={expandedQuery === keywordOption.keyword.query}
                                              onClickExpand={() =>
                                                  setExpandedQuery(
                                                      expandedQuery !== keywordOption.keyword.query
                                                          ? keywordOption.keyword.query
                                                          : undefined
                                                  )
                                              }
                                          />
                                      ))}
                                  </div>
                                  <div>
                                      {hasMeasuresArr.map((keywordOption) => (
                                          <KeywordRow
                                              key={keywordOption.keyword.query}
                                              className={c.rowWithMeasures}
                                              keywordOption={keywordOption}
                                              isExpanded={expandedQuery === keywordOption.keyword.query}
                                              onClickExpand={() =>
                                                  setExpandedQuery(
                                                      expandedQuery !== keywordOption.keyword.query
                                                          ? keywordOption.keyword.query
                                                          : undefined
                                                  )
                                              }
                                          />
                                      ))}
                                  </div>
                              </div>
                          );
                      })()}
            </div>
        </div>
    );
};
KeywordsTable.displayName = '@@construction/WholePage/Authorized/KeywordsTable';
