import { FC, ReactNode, useState, useCallback } from 'react';

import { Category, getIsCategory, ScTerm } from '@@models';
import { retrieveSearchProp, updateSearch as updateSearchText } from '@@utils';

import { LocationCtx, SortBy, isSortBy } from './LocationCtx';

const getSortBy = (search: string | undefined): SortBy => {
    if (search) {
        const strValue = retrieveSearchProp({ search, key: 'sortBy' });
        if (strValue && isSortBy(strValue)) {
            return strValue as SortBy;
        }
    }
    return 'losingVolume';
};
const getFilterByCategory = (search: string | undefined): Category | undefined => {
    if (search) {
        const strValue = retrieveSearchProp({ search, key: 'filterByCategory' });
        if (strValue && getIsCategory(strValue)) {
            return strValue;
        }
    }
    return undefined;
};
const getFilterByWord = (search: string | undefined): string | undefined => {
    if (search) {
        return retrieveSearchProp({ search, key: 'filterByWord' });
    }
    return undefined;
};
const getFilterByVolume = (search: string | undefined): number | undefined => {
    if (search) {
        const strValue = retrieveSearchProp({ search, key: 'filterByVolume' });
        if (strValue) {
            const numed = Number(strValue);
            if (!isNaN(numed)) {
                return numed;
            }
        }
    }
    return undefined;
};
const getTerm = (search: string | undefined): ScTerm => {
    if (search) {
        const strValue = retrieveSearchProp({ search, key: 'term' });
        if (strValue && ['1w', '4w', '12w'].includes(strValue)) {
            return strValue as ScTerm;
        }
    }
    return '1w';
};

export const LocationProvider: FC<{
    children: ReactNode;
}> = ({ children }) => {
    // HOOKS
    const [pathname, setPathname] = useState<string>(window.location.pathname);
    const [hash, setHash] = useState<string | undefined>(window.location.hash || undefined);
    const [search, setSearch] = useState<string | undefined>(window.location.search || undefined);

    // DATA
    const sortBy = getSortBy(search);
    const filterByCategory = getFilterByCategory(search);
    const filterByWord = getFilterByWord(search);
    const filterByVolume = getFilterByVolume(search);
    const term = getTerm(search);

    // CALLBACK
    const updateSearch = useCallback(
        ({ newSearch, isReplace }: { newSearch: string | undefined; isReplace: boolean }) => {
            const newUrl = pathname + (newSearch || '') + (hash || '');
            if (isReplace) {
                window.history.replaceState({}, '', newUrl);
            } else {
                window.history.pushState({}, '', newUrl);
            }
            setSearch(newSearch);
        },
        [pathname, hash]
    );
    const updateSortBy = useCallback(
        (input: SortBy) => {
            const newSearch = updateSearchText({
                search: search || '',
                key: 'sortBy',
                value: input,
            });
            updateSearch({ newSearch, isReplace: true });
        },
        [updateSearch, search]
    );
    const updateFilterByCategory = useCallback(
        (input: Category | undefined) => {
            const newSearch = updateSearchText({
                search: search || '',
                key: 'filterByCategory',
                value: input,
            });
            updateSearch({ newSearch, isReplace: true });
        },
        [updateSearch, search]
    );
    const updateFilterByWord = useCallback(
        (input: string | undefined) => {
            const newSearch = updateSearchText({
                search: search || '',
                key: 'filterByWord',
                value: input,
            });
            updateSearch({ newSearch, isReplace: true });
        },
        [updateSearch, search]
    );
    const updateFilterByVolume = useCallback(
        (input: number | undefined) => {
            const newSearch = updateSearchText({
                search: search || '',
                key: 'filterByVolume',
                value: input ? String(input) : undefined, // 0 も含んでよい
            });
            updateSearch({ newSearch, isReplace: true });
        },
        [updateSearch, search]
    );
    const updateTerm = useCallback(
        (input: ScTerm) => {
            const newSearch = updateSearchText({
                search: search || '',
                key: 'term',
                value: input,
            });
            updateSearch({ newSearch, isReplace: true });
        },
        [updateSearch, search]
    );

    // console.log({ sortBy, filterByCategory, filterByWord, filterByVolume, term });

    return (
        <LocationCtx.Provider
            value={{
                search,
                sortBy,
                filterByCategory,
                filterByWord,
                filterByVolume,
                term,
                updateSearch,
                updateSortBy,
                updateFilterByCategory,
                updateFilterByVolume,
                updateFilterByWord,
                updateTerm,
            }}
        >
            {children}
        </LocationCtx.Provider>
    );
};
LocationProvider.displayName = 'LocationProvider';
