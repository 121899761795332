import { encryptPki, getEnvVariables } from '@@utils';

export const redirectToGoogle = ({ scopes }: { scopes: string[] }) => {
    const redirectTo = new URL('https://accounts.google.com/o/oauth2/v2/auth');
    const { GOOGLE_CLIENT_ID, OAUTH_REDIRECT_URI, PKI_PUBLIC_KEY } = getEnvVariables();
    redirectTo.search = new URLSearchParams({
        client_id: GOOGLE_CLIENT_ID,
        // redirect_uri: 'https://asia-northeast1-keywordmanager-aed46.cloudfunctions.net/receiveOauthRedirect',
        redirect_uri: OAUTH_REDIRECT_URI,
        response_type: 'code',
        scope: scopes.join(' '),
        state: encryptPki({
            raw: new Date().toISOString(),
            key: PKI_PUBLIC_KEY,
            keyKind: 'public',
        }),
        access_type: 'offline',
    }).toString();
    window.location.href = redirectTo.href;
};
