import { getNotes, listenToNotes } from '@@Rdb';
import { DispatchNotes } from '../useDomainData';

export const initNotes = async ({ domain, dispatchNotes }: { domain: string; dispatchNotes: DispatchNotes }) => {
    const notes = await getNotes({ domain });
    dispatchNotes({
        setNotes: {
            update: () => notes,
        },
    });

    const { unsubscribeListenToNotes } = listenToNotes({
        domain,
        onAdded: (addedNote, key) => {
            dispatchNotes({
                setNote: {
                    key,
                    update: () => addedNote,
                },
            });
        },
        onChanged: (changedNote, key) => {
            dispatchNotes({
                setNote: {
                    key,
                    update: () => changedNote,
                },
            });
        },
        onRemoved: (removedKey) => {
            dispatchNotes({
                removeNote: {
                    key: removedKey,
                },
            });
        },
    });

    return unsubscribeListenToNotes;
};
