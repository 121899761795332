import { Measures } from '@@models';

export const setMeasures = ({
    prev,
    update,
}: {
    prev: Loading<Measures>;
    update: (prev: Loading<Measures>) => Measures;
}): Measures => {
    const current = prev === 'loading' ? 'loading' : new Measures({ ...prev.data });
    return update(current);
};
