import { createContext, useContext } from 'react';

import { PresenceStates } from '@@models';

type PresenceStatesCtxType = {
    presenceStates: Loading<PresenceStates>;
};

export const PresenceStatesCtx = createContext<PresenceStatesCtxType>({
    presenceStates: 'loading',
});

export const usePresenceStatesCtx = () => {
    return useContext(PresenceStatesCtx);
};

export const usePresenceStatesCtxAbsolutely = () => {
    const { presenceStates } = usePresenceStatesCtx();
    if (presenceStates === 'loading') {
        throw new Error('usePresenceStatesCtxAbsolutely: presenceStates === loading.');
    }
    return {
        presenceStates,
    };
};
