import { remove } from 'firebase/database';

import { getRdbNoteRef } from './RdbNote';

export const removeRdbNote = ({
    domain,
    keywordText,
    userId,
}: {
    domain: string;
    keywordText: string;
    userId: string;
}) => {
    const noteRef = getRdbNoteRef({ domain, keywordText, userId });
    remove(noteRef);
};
