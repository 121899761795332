import { createContext, useContext } from 'react';

import { FirebaseUser } from '@@firebase';

type FirebaseUserCtxType = {
    firebaseUser: Loading<FirebaseUser | undefined>;
};

export const FirebaseUserCtx = createContext<FirebaseUserCtxType>({
    firebaseUser: 'loading',
});

export const useFirebaseUserCtx = () => {
    return useContext(FirebaseUserCtx);
};

export const useFirebaseUserCtxAbsolutely = () => {
    const { firebaseUser } = useFirebaseUserCtx();
    if (firebaseUser === 'loading' || !firebaseUser) {
        throw new Error('useFirebaseUserCtxAbsolutely: firebaseUser === loading || !firebaseUser.');
    }
    return {
        firebaseUser,
    };
};
