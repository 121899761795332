import { onAuthStateChanged, User as FirebaseUser } from 'firebase/auth';

import { FirebaseProjectName } from '../firebaseConfig';
import { getFirebaseAuth } from './getFirebaseAuth';

export const setOnAuthStateChanged = ({
    projectName,
    handler,
}: {
    projectName: FirebaseProjectName;
    handler: (user: FirebaseUser | undefined) => void;
}) => {
    const unsubscribeOnAuthStateChanged = onAuthStateChanged(getFirebaseAuth({ projectName }), (user) =>
        handler(user || undefined)
    );
    return {
        unsubscribeOnAuthStateChanged,
    };
};
