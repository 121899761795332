import { ScOffset, ScQueryResultItem, ScTerm } from './ScQueryResult';

export const getScResultItem = ({
    scQueryResults,
    term,
    offset,
}: {
    scQueryResults: {
        [term in ScTerm]?: {
            [offset in ScOffset]?: {
                primary: ScQueryResultItem;
                others: ScQueryResultItem[];
            };
        };
    };
    term: ScTerm;
    offset: ScOffset;
}): Optional<{
    primary: ScQueryResultItem;
    others: ScQueryResultItem[];
}> => {
    const termItem = scQueryResults[term];
    if (termItem) {
        return termItem[offset];
    }
    return undefined;
};
