import { getWithRetry } from '@@firebase';
import { Pin, Pins, parsePinIdentifier } from '@@models';

import { getRdbDataMap } from '../utils';
import { getRdbPinsRef, getRdbPinFromSource, RdbPin } from '../RdbModels';

export const getPins = async ({ domain }: { domain: string }): Promise<Pins> => {
    const pinsRef = getRdbPinsRef({ domain });
    const data = await getWithRetry(pinsRef);
    const pins = getRdbDataMap(data, (key, props: RdbPin<undefined, number>) => {
        const { keywordText, userId } = parsePinIdentifier({ pinIdentifier: key });
        return new Pin({
            keywordText,
            userId,
            ...getRdbPinFromSource(props),
        });
    });
    return new Pins(pins);
};
