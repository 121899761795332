import { FC, CSSProperties, useState, useCallback, KeyboardEvent, useRef } from 'react';

import MuiInputBase from '@mui/material/InputBase';

import { createClassNames, createClassNamesGenerator, cx } from '@@styles';
import { Note } from '@@models';
import { addRdbNote, modifyRdbNoteText, removeRdbNote } from '@@Rdb';
import { useOptionsCtxAbsolutely, useDomainCtxAbsolutely } from '@@ctx';

const c = createClassNames({
    root: {},
    text: {
        '& input:focus': {
            outline: 'solid 1px #000',
        },
    },
});

const f = createClassNamesGenerator({});

export const NoteEditor: FC<{
    className?: string;
    style?: CSSProperties;
    note: Note | undefined;
    keywordText: string;
    onClose: () => void;
}> = ({ className, style, note, keywordText, onClose }) => {
    // REF
    const inputRef = useRef<HTMLInputElement | null>(null);

    // CTX
    const { userOption } = useOptionsCtxAbsolutely();
    const { domain } = useDomainCtxAbsolutely();

    // STATE
    const [text, setText] = useState<string | undefined>(note?.text);
    const [isComposing, setIsComposing] = useState(false);

    // CALLBACK
    const submit = useCallback(() => {
        if (text) {
            if (note) {
                modifyRdbNoteText({ domain, keywordText, text, userId: userOption.user.id });
            } else {
                addRdbNote({ domain, keywordText, text, userId: userOption.user.id });
            }
        } else if (note) {
            removeRdbNote({ domain, keywordText, userId: userOption.user.id });
        }
        onClose();
    }, [domain, text, !!note, userOption.user.id]);
    const handleKeydown = useCallback(
        (event: KeyboardEvent<HTMLDivElement>) => {
            if (event.key === 'enter' && !isComposing) {
                event.preventDefault();
                inputRef.current?.blur();
            }
        },
        [isComposing]
    );

    return (
        <div
            className={cx(c.root, className)}
            style={{
                color: userOption.user.color.getCssValue({ opacity: 1 }),
                // background: userOption.user.color.getCssValue({ opacity: 0.3 }),
                ...style,
            }}
        >
            <MuiInputBase
                className={c.text}
                inputRef={inputRef}
                style={{
                    color: userOption.user.color.getCssValue({ opacity: 1 }),
                }}
                value={text || ''}
                onChange={(event) => {
                    setText(event.target.value || undefined);
                }}
                fullWidth
                onBlur={submit}
                onKeyDownCapture={handleKeydown}
                onCompositionStart={() => setIsComposing(true)}
                onCompositionEnd={() => setIsComposing(false)}
            />
            {/* <div onClick={onClose} className={c.cancelButton}>
                {'キャンセル'}
            </div>
            <div onClick={submit} className={c.submitButton}>
                {'保存'}
            </div> */}
        </div>
    );
};
NoteEditor.displayName = '@@construction/WholePage/Authorized/KeywordsTable/ChunkRow/KeywordRow/NotesPart/NoteEditor';
