import { useReducer, useCallback } from 'react';

export type Unsubscribe = () => void;
export type SetUnsubscriber = (unsubscriber: () => void) => void;

type DispatchUnsubscriberInput = {
    set?: {
        unsubscriber: () => void;
    };
    unsubscribe?: boolean;
};

export const useUnsubscriber = () => {
    const [_unsubscribers, dispatchUnsubscribers] = useReducer<
        (state: Unsubscribe | undefined, input: DispatchUnsubscriberInput) => Unsubscribe | undefined
    >((state, { set, unsubscribe }) => {
        if (set) {
            return set.unsubscriber;
        }
        if (unsubscribe) {
            if (state) {
                state();
            }
            return undefined;
        }
        throw new Error('useUnsubscribers: Unexpected.');
    }, undefined);

    const unsubscribe = useCallback(() => {
        dispatchUnsubscribers({ unsubscribe: true });
    }, []);
    const setUnsubscriber = useCallback((unsubscriber: () => void) => {
        dispatchUnsubscribers({ set: { unsubscriber } });
    }, []);

    return {
        unsubscribe,
        setUnsubscriber,
    };
};
