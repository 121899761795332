import { getRdbRef } from '@@firebase';

// REF
export const getRdbPresenceStatesRef = ({ domain }: { domain: string }) => {
    return getRdbRef({
        projectName: 'default',
        path: `presenceStates/${domain}`,
    });
};
export const getRdbPresenceStateRef = ({ domain, userId }: { domain: string; userId: string }) => {
    return getRdbRef({
        projectName: 'default',
        path: `presenceStates/${domain}/${userId}`,
    });
};

// TYPE
export type RdbPresenceState<_TUndefined extends undefined | null = undefined, TDate extends Date | number = Date> = {
    // KNOWN: domain, userId

    // SYSTEM
    createdAt: TDate;
    updatedAt: TDate;

    // DATA
    isWatching: boolean;
};
export type RdbPresenceStates<TUndefined extends undefined | null = undefined, TDate extends Date | number = Date> = {
    [userId: string]: RdbPresenceState<TUndefined, TDate>;
};

export const getRdbPresenceState = ({
    createdAt,
    updatedAt,
    isWatching,
}: RdbPresenceState<undefined, Date>): RdbPresenceState<undefined, Date> => {
    return { createdAt, updatedAt, isWatching };
};

export const getRdbPresenceStateInput = ({
    createdAt,
    updatedAt,
    isWatching,
}: RdbPresenceState<undefined, Date>): RdbPresenceState<null, number> => {
    return {
        createdAt: createdAt.getTime(),
        updatedAt: updatedAt.getTime(),
        isWatching,
    };
};

export const getRdbPresenceStateFromSource = ({
    createdAt,
    updatedAt,
    isWatching,
}: RdbPresenceState<undefined, number>): RdbPresenceState<undefined, Date> => {
    return {
        createdAt: new Date(createdAt),
        updatedAt: new Date(updatedAt),
        isWatching,
    };
};
