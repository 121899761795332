import { FC, CSSProperties } from 'react';
import MuiCollapse from '@mui/material/Collapse';

import { createClassNames, createClassNamesGenerator, tablePalette, cx } from '@@styles';
import { useLocationCtx } from '@@ctx';
import { ScOffset, ScTerm } from '@@models';

import { TransitionView } from './TransitionView';

const c = createClassNames({
    root: {
        flexGrow: 0,
        flexShrink: 0,
    },
    overview: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    left: {},
    right: {
        textAlign: 'right',
    },
    leftItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    leftItemValue: {
        fontSize: 12,
    },
    transition: {},
});

const f = createClassNamesGenerator({
    leftItemPositive: ({ positive }: { positive: 'positive' | 'negative' | 'keep' }) => ({
        color:
            positive === 'positive' ? tablePalette.good.text : positive === 'negative' ? tablePalette.bad.text : '#aaa',
        background:
            positive === 'positive'
                ? tablePalette.good.background
                : positive === 'negative'
                ? tablePalette.bad.background
                : '#fafafa',
        borderRadius: 2,
        fontSize: 12,
        padding: '1px 4px',
    }),
});

const getIncrease = (diff: number) => {
    return diff < 0 ? 'decrease' : diff > 0 ? 'increase' : 'keep';
};
const getPositive = ({
    increase,
    positiveDirection,
}: {
    increase: 'keep' | 'decrease' | 'increase';
    positiveDirection: 'up' | 'down';
}): 'positive' | 'negative' | 'keep' => {
    if (increase === 'keep') {
        return 'keep';
    }
    if (increase === 'increase' && positiveDirection === 'up') {
        return 'positive';
    }
    if (increase === 'increase' && positiveDirection === 'down') {
        return 'negative';
    }
    if (increase === 'decrease' && positiveDirection === 'up') {
        return 'negative';
    }
    if (increase === 'decrease' && positiveDirection === 'down') {
        return 'positive';
    }
    throw new Error(`getPositive: unexpected pattern: increase:${increase}, positiveDirection:${positiveDirection}`);
};
const getDiffLetter = ({
    positive,
    letterForPositive,
}: {
    positive: 'positive' | 'negative' | 'keep';
    letterForPositive: '+' | '-' | '↑' | '↓';
}) => {
    if (positive === 'keep') {
        return '→';
    }
    if (letterForPositive === '+') {
        return positive === 'positive' ? '+' : '-';
    }
    if (letterForPositive === '-') {
        return positive === 'positive' ? '-' : '+';
    }
    if (letterForPositive === '↑') {
        return positive === 'positive' ? '↑' : '↓';
    }
    if (letterForPositive === '↓') {
        return positive === 'positive' ? '↓' : '↑';
    }
    throw new Error(`getDiffLetter: unexpected pattern: positive:${positive}, letterForPositive:${letterForPositive}`);
};

export const ComparingView: FC<{
    className?: string;
    style?: CSSProperties;
    rightWidth?: number;
    prev: number;
    current: number;
    best: number;
    vsPrev: number;
    vsBest: number;
    fixer: (value: number, kind: 'value' | 'diff') => string;
    positiveDirection: 'up' | 'down';
    letterForPositive: '+' | '-' | '↑' | '↓';
    transition?: {
        isExpanded: boolean;
        value: {
            [term in ScTerm]?: {
                [offset in ScOffset]?: number;
            };
        };
        termStyle?: CSSProperties;
        valueStyle?: CSSProperties;
    };
    onClick?: () => void;
}> = ({
    className,
    style,
    rightWidth,
    prev,
    current,
    best,
    vsPrev,
    vsBest,
    fixer,
    positiveDirection,
    letterForPositive,
    transition,
    onClick,
}) => {
    // CTX
    const { term } = useLocationCtx();

    // DATA
    const prevIncrease = getIncrease(vsPrev);
    const bestIncrease = getIncrease(vsBest);
    const prevPositive = getPositive({ increase: prevIncrease, positiveDirection });
    const bestPositive = getPositive({ increase: bestIncrease, positiveDirection });
    return (
        <div className={cx(c.root, className)} style={style} onClick={onClick}>
            <div className={c.overview}>
                <div className={c.left}>
                    <div className={c.leftItem}>
                        <div className={c.leftItemValue}>{`前の${
                            term === '1w' ? '週' : term === '4w' ? '4週' : term === '12w' ? '12週' : '不正な期間'
                        }: ${fixer(prev, 'value')}`}</div>
                        <div
                            className={f.leftItemPositive({
                                positive: prevPositive,
                            })}
                        >
                            {`${getDiffLetter({ positive: prevPositive, letterForPositive })}${fixer(
                                Math.abs(vsPrev),
                                'diff'
                            )}`}
                        </div>
                    </div>
                    <div className={c.leftItem}>
                        <div className={c.leftItemValue}>{`ベスト: ${fixer(best, 'value')}`}</div>
                        <div
                            className={f.leftItemPositive({
                                positive: bestPositive,
                            })}
                        >
                            {`${getDiffLetter({ positive: bestPositive, letterForPositive })}${fixer(
                                Math.abs(vsBest),
                                'diff'
                            )}`}
                        </div>
                    </div>
                </div>
                <div className={c.right} style={{ width: rightWidth }}>
                    {fixer(current, 'value')}
                </div>
            </div>
            {transition && (
                <MuiCollapse in={transition.isExpanded}>
                    <TransitionView
                        className={c.transition}
                        transitions={transition.value}
                        fixer={(value) => fixer(value, 'value')}
                        termStyle={transition.termStyle}
                        valueStyle={transition.valueStyle}
                        best={best}
                    />
                </MuiCollapse>
            )}
        </div>
    );
};
ComparingView.displayName = '@@construction/WholePage/Authorized/KeywordsTable/ChunkRow/KeywordRow/ComparingView';
