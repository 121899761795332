import { DataSnapshot } from 'firebase/database';

export const getRdbData = <TSource extends Record<string, any>, TRdbData extends Record<string, any>>(
    data: DataSnapshot,
    getter: (input: TSource) => TRdbData,
    options?: {
        keyGetter?: (input: string) => string;
    }
) => {
    if (data.key) {
        const value = data.val();
        if (value) {
            return {
                id: options?.keyGetter ? options.keyGetter(data.key) : data.key,
                ...getter(value),
            };
        }
    }
    return undefined;
};
