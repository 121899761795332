import { ScTerm, ScOffset, serializeScQueryResultIdentifier } from '@@models';
import { getYYYYMMDD } from '@@utils';
import { downloadBlobFromCloudStorage } from '@@firebase';

import { CsScQueryResult } from './CsScQueryResult';

export const getCsScQueryResult = async ({
    domain,
    fetchDate,
    term,
    offset,
}: {
    domain: string;
    fetchDate: Date;
    term: ScTerm;
    offset: ScOffset;
}): Promise<CsScQueryResult | undefined> => {
    const response = await downloadBlobFromCloudStorage({
        projectName: 'default',
        path: `${domain}/${getYYYYMMDD(fetchDate)}/${serializeScQueryResultIdentifier({ term, offset })}.json`,
    }).catch((e) => {
        console.error(
            'getCsScQueryResult:',
            {
                domain,
                fetchDate,
                term,
                offset,
            },
            e
        );
        return undefined;
    });
    const text = await response?.text();
    if (text) {
        return JSON.parse(text) as CsScQueryResult;
    }
    return undefined;
};
