import { ScOffset, ScTerm } from '../interfaces';

import { ScQueryResult } from '../rawModels';

export class ScQueryResults {
    data: {
        [term in ScTerm]?: {
            [offset in ScOffset]?: ScQueryResult;
        };
    };

    constructor(data: {
        [term in ScTerm]?: {
            [offset in ScOffset]?: ScQueryResult;
        };
    }) {
        this.data = data;
    }
}
