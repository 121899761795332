import { FirebaseUser } from '@@firebase';
import {
    Measures,
    Notes,
    Pins,
    Users,
    Keywords,
    ScQueryResults,
    PresenceStates,
    NoteOptions,
    UserOptions,
    KeywordOptions,
    MeasureOptions,
    NoteOption,
    UserOption,
    KeywordOption,
    MeasureOption,
    ScTerm,
} from '@@models';

import { OptionsCtxType } from '../OptionsCtx';

import { getQueryAnalysis } from './getQueryAnalysis';
import { getScQueryResultsForKeyword } from './getScQueryResultsForKeyword';

export const computeOptions = ({
    firebaseUser,
    measures,
    notes,
    pins,
    users,
    presenceStates,
    scQueryResults: scQueryResults_,
    keywords,
    term,
}: {
    firebaseUser: FirebaseUser;
    measures: Loading<Measures>;
    notes: Loading<Notes>;
    pins: Loading<Pins>;
    users: Loading<Users>;
    presenceStates: Loading<PresenceStates>;
    scQueryResults: Loading<ScQueryResults>;
    keywords: Loading<Keywords>;
    term: ScTerm;
}): OptionsCtxType => {
    const userOptions = (() => {
        if (users === 'loading' || presenceStates === 'loading') {
            return 'loading';
        }
        return new UserOptions(
            users.reduce((acc, userId, user) => {
                const presenceState = presenceStates.data[userId];
                return {
                    ...acc,
                    [userId]: new UserOption({ user, presenceState }),
                };
            }, {} as { [userId: string]: UserOption })
        );
    })();
    const noteOptions = (() => {
        if (notes === 'loading' || userOptions === 'loading') {
            return 'loading';
        }
        return new NoteOptions(
            notes.reduce((acc, noteIdentifier, note) => {
                const creatorUserOption = userOptions.data[note.userId];
                if (!creatorUserOption) {
                    return acc;
                }
                return {
                    ...acc,
                    [noteIdentifier]: new NoteOption({
                        note,
                        userOption: creatorUserOption,
                    }),
                };
            }, {} as { [keywordText: string]: NoteOption })
        );
    })();
    const measureOptions = (() => {
        if (measures === 'loading' || userOptions === 'loading') {
            return 'loading';
        }
        return new MeasureOptions(
            measures.reduce((acc, measureId, measure) => {
                const creatorUserOption = userOptions.data[measure.userId];
                if (!creatorUserOption) {
                    return acc;
                }
                return {
                    ...acc,
                    [measureId]: new MeasureOption({ measure, userOption: creatorUserOption }),
                };
            }, {} as { [measureId: string]: MeasureOption })
        );
    })();
    const keywordOptions = (() => {
        if (
            keywords === 'loading' ||
            scQueryResults_ === 'loading' ||
            userOptions === 'loading' ||
            measureOptions === 'loading' ||
            noteOptions === 'loading' ||
            pins === 'loading'
        ) {
            return 'loading';
        }
        return new KeywordOptions(
            keywords.reduce((acc, keywordText, keyword) => {
                const scQueryResults = getScQueryResultsForKeyword({ keywordText, scQueryResults: scQueryResults_ });
                const analysis = getQueryAnalysis({ keyword, scQueryResults, term });
                return {
                    ...acc,
                    [keywordText]: new KeywordOption({
                        keyword,
                        scResults: scQueryResults,
                        analysis,
                        measureOptions: new MeasureOptions(
                            measureOptions.filter(
                                (_, measureOption) => measureOption.measure.keywordText === keywordText
                            ).data
                        ),
                        noteOptions: new NoteOptions(
                            noteOptions.filter((_, noteOption) => noteOption.note.keywordText === keywordText).data
                        ),
                        pins: new Pins(pins.filter((_, pin) => pin.keywordText === keywordText).data),
                    }),
                };
            }, {} as { [keywordText: string]: KeywordOption })
        );
    })();
    // const keywordChunkOptions = (() => {
    //     if (keywordOptions === 'loading') {
    //         return 'loading';
    //     }
    //     return new KeywordChunkOptions(
    //         keywordOptions.reduce((acc, keywordText, keywordOption) => {
    //             const shuffles = {}; // later
    //             const aliases = {}; // later
    //             return {
    //                 ...acc,
    //                 [keywordText]: new KeywordChunkOption({
    //                     keywordOptions: new KeywordOptions({
    //                         [keywordText]: keywordOption,
    //                         ...shuffles,
    //                         ...aliases,
    //                     }),
    //                 }),
    //             };
    //         }, {} as { [keywordText: string]: KeywordChunkOption })
    //     );
    // })();
    const userOption = (() => {
        if (userOptions === 'loading') {
            return 'loading';
        }
        return userOptions.data[firebaseUser.uid];
    })();

    return {
        noteOptions,
        userOptions,
        keywordOptions,
        measureOptions,
        // keywordChunkOptions,
        userOption,
    };
};
