export const tablePalette = {
    good: {
        text: '#00cd16',
        background: '#e1ffe4',
    },
    bad: {
        text: '#ff0000',
        background: '#ffe7e7',
    },
};
