import { Users, User } from '@@models';

import { setUsers } from './setUsers';

export const setUser = ({
    prev,
    key,
    update,
}: {
    prev: Users;
    key: string;
    update: (prev: User | undefined) => User;
}): Users => {
    return setUsers({
        prev,
        update: (prevUsers) => {
            if (prevUsers === 'loading') {
                throw new Error('setUser: prevUsers === loading.');
            }
            prevUsers.update(key, update);
            return prevUsers;
        },
    });
};
