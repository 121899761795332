import { useCallback } from 'react';

import { FirebaseUser, setOnAuthStateChanged } from '@@firebase';
import { useUnsubscriber } from '@@utils';

export const useInitFirebaseUser = ({
    firebaseUser,
    setFirebaseUser,
}: {
    firebaseUser: Loading<FirebaseUser | undefined>;
    setFirebaseUser: (firebaseUser: FirebaseUser | undefined) => void;
}) => {
    // STATE
    const { unsubscribe: unsubscribeFirebaseUser, setUnsubscriber } = useUnsubscriber();

    // CALLBAK
    const initFirebaseUser = useCallback(({ refreshIntervalMin }: { refreshIntervalMin: number }) => {
        unsubscribeFirebaseUser();

        const { unsubscribeOnAuthStateChanged } = setOnAuthStateChanged({
            projectName: 'default',
            handler: (firebaseUser) => {
                setFirebaseUser(firebaseUser);
                firebaseUser?.getIdToken(false);
            },
        });
        const timer = setInterval(
            () => {
                if (firebaseUser && firebaseUser !== 'loading') {
                    firebaseUser.getIdToken(true);
                }
            },
            1000 * 60 * refreshIntervalMin,
            1000 * 60 * refreshIntervalMin
        );

        setUnsubscriber(() => {
            unsubscribeOnAuthStateChanged();
            clearInterval(timer);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        initFirebaseUser,
        unsubscribeFirebaseUser,
    };
};
