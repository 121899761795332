import { getApp, initializeApp } from 'firebase/app';

import { firebaseConfig, FirebaseProjectName } from './firebaseConfig';

export const getFirebaseApp = ({ projectName }: { projectName: FirebaseProjectName }) => {
    const existinApp = (() => {
        try {
            return getApp(projectName);
        } catch {
            return undefined;
        }
    })();
    if (existinApp) {
        return existinApp;
    }
    return initializeApp(firebaseConfig, projectName);
};
