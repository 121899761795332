import { getWithRetry } from '@@firebase';
import { PresenceState, PresenceStates } from '@@models';

import { getRdbDataMap } from '../utils';
import { getRdbPresenceStatesRef, getRdbPresenceStateFromSource, RdbPresenceState } from '../RdbModels';

export const getPresenceStates = async ({ domain }: { domain: string }): Promise<PresenceStates> => {
    const presenceStatesRef = getRdbPresenceStatesRef({ domain });
    const data = await getWithRetry(presenceStatesRef);
    const presenceStates = getRdbDataMap(
        data,
        (key, props: RdbPresenceState<undefined, number>) =>
            new PresenceState({
                userId: key,
                ...getRdbPresenceStateFromSource(props),
            })
    );
    return new PresenceStates(presenceStates);
};
