import { get, Query, DataSnapshot } from 'firebase/database';

export const getWithRetry = async (query: Query, retryInterval?: number) => {
    return get(query).catch(() => {
        return new Promise<DataSnapshot>((resolve) => {
            const retry = () => {
                get(query)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch(() => {
                        setTimeout(retry, retryInterval || 1000);
                    });
            };
            setTimeout(retry, retryInterval || 1000);
        });
    });
};
