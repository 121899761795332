export class Pin {
    // SYSTEM
    readonly createdAt: Date;

    // DATA
    keywordText: string;
    userId: string;

    constructor({
        createdAt,
        keywordText,
        userId,
    }: {
        // SYSTEM
        readonly createdAt: Date;

        // DATA
        keywordText: string;
        userId: string;
    }) {
        this.createdAt = createdAt;
        this.keywordText = keywordText;
        this.userId = userId;
    }
}
