import { useMeasures } from './useMeasures';
import { useNotes } from './useNotes';
import { usePins } from './usePins';
import { usePresenceStates } from './usePresenceStates';
import { useScQueryResults } from './useScQueryResults';
import { useUsers } from './useUsers';

export const useDomainData = () => {
    // STATE
    const { measures, dispatchMeasures } = useMeasures();
    const { notes, dispatchNotes } = useNotes();
    const { pins, dispatchPins } = usePins();
    const { presenceStates, dispatchPresenceStates } = usePresenceStates();
    const { scQueryResults, dispatchScQueryResults } = useScQueryResults();
    const { users, dispatchUsers } = useUsers();

    return {
        measures,
        dispatchMeasures,
        notes,
        dispatchNotes,
        pins,
        dispatchPins,
        presenceStates,
        dispatchPresenceStates,
        scQueryResults,
        dispatchScQueryResults,
        users,
        dispatchUsers,
    };
};

export type { DispatchMeasures } from './useMeasures';
export type { DispatchNotes } from './useNotes';
export type { DispatchPins } from './usePins';
export type { DispatchPresenceStates } from './usePresenceStates';
export type { DispatchScQueryResults } from './useScQueryResults';
export type { DispatchUsers } from './useUsers';
