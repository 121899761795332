export const tableCellWidths = {
    pin: 55,
    categories: 75,
    volume: 65,
    query: 260,
    page: 300,
    // scLink: 50,
    position: 275, // 225
    ctr: 295, // 255
    score: 245,
    clicks: 75,
    impressions: 75,
    coverage: 75,
    losingVolume: 95,
    priority: 75,
    measures: 400,
    notes: 500,
};
