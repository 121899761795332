import { Category } from '@@models';

import { keywordVolumesOver100 } from './keywordVolumesOver100';

export type KeywordCategoryDefs = {
    [keywordText in keyof typeof keywordVolumesOver100]?: Category[];
};

export const keywordCategoryDefs: KeywordCategoryDefs = {
    'ウーバーイーツ 始め 方': ['配達の仕事'],
    'uber eats 配達 員': ['配達の仕事'],
    'uber eats 配達 料': ['フード注文', '配達の仕事', 'レストラン'],
    'uber 登録 方法': ['フード注文', '配達の仕事', 'レストラン'],
    '出前 館 登録 方法': ['フード注文', '配達の仕事', 'レストラン'],
    '出前 館 配達 方法': ['配達の仕事'],
};
