import { remove } from 'firebase/database';

import { getRdbPinRef } from './RdbPin';

export const removeRdbPin = ({
    domain,
    keywordText,
    userId,
}: {
    domain: string;
    keywordText: string;
    userId: string;
}) => {
    const pinRef = getRdbPinRef({ domain, keywordText, userId });
    remove(pinRef);
};
