import { getWithRetry } from '@@firebase';
import { Measure, Measures } from '@@models';

import { getRdbDataMap } from '../utils';
import { getRdbMeasuresRef, getRdbMeasureFromSource, RdbMeasure } from '../RdbModels';

export const getMeasures = async ({ domain }: { domain: string }): Promise<Measures> => {
    const measuresRef = getRdbMeasuresRef({ domain });
    const data = await getWithRetry(measuresRef);
    const measures = getRdbDataMap(
        data,
        (key, props: RdbMeasure<undefined, number>) =>
            new Measure({
                id: key,
                ...getRdbMeasureFromSource(props),
            })
    );
    return new Measures(measures);
};
