import { FC } from 'react';

import { createClassNames } from '@@styles';
import { useFirebaseUserCtxAbsolutely, useLocationCtx } from '@@ctx';
import { DomainDataProvider } from '@@ctx';

import { KeywordsTable } from './KeywordsTable';

const c = createClassNames({
    root: {
        // padding: 30,
    },
    header: {
        background: '#aaa',
    },
    tableContainer: {
        // padding: 30,
        width: 'fit-content',
        height: 'fit-content',
        // // display: 'flex',
        // overflow: 'scroll',
        // overscrollBehaviorX: 'none',
    },
});

export const Authorized: FC = () => {
    // CTX
    const { term } = useLocationCtx();
    const { firebaseUser } = useFirebaseUserCtxAbsolutely();

    // useEffect(() => {
    //     console.log({
    //         keywordsOver100,
    //         0: getTrimmedKeywordTexts({ indexGroup: 0 }),
    //         1: getTrimmedKeywordTexts({ indexGroup: 1 }),
    //         2: getTrimmedKeywordTexts({ indexGroup: 2 }),
    //         3: getTrimmedKeywordTexts({ indexGroup: 3 }),
    //     });
    // }, []);

    return (
        <DomainDataProvider term={term} domain={'noshift.com'} firebaseUser={firebaseUser}>
            <div className={c.root}>
                <div className={c.tableContainer}>
                    <KeywordsTable />
                </div>
            </div>
        </DomainDataProvider>
    );
};
Authorized.displayName = '@@construction/WholePage/Authorized';
