import { FC, useState, useEffect } from 'react';

import MuiButton from '@mui/material/Button';
import MuiCircularProgress from '@mui/material/CircularProgress';

import { createClassNames, theme, cx } from '@@styles';
import { useFirebaseUserCtx } from '@@ctx';
import { FirebaseUser, signinWithGoogle } from '@@firebase';

import SvgGoogle from '@@svgs/google-g';
import SvgCheck from '@@svgs/fa-check-light';

const c = createClassNames({
    root: {
        border: 'solid 2px rgba(0,0,0,.03)',
        padding: '14px 23px',
        width: 230,
        justifyContent: 'flex-start',
        background: '#fff',
    },
    progress: {
        margin: 5,
    },
    googleIcon: {
        width: 24,
        height: 24,
    },
    text: {
        fontWeight: 600,
        fontSize: theme.typography.body2.fontSize,
        display: 'inline-block',
        marginLeft: 19,
    },
});

export const SignInWithGoogleButton: FC<{
    className?: string;
    onAuthed?: (firebaseUser: FirebaseUser) => void;
}> = ({ className, onAuthed }) => {
    // HOOKS
    const { firebaseUser } = useFirebaseUserCtx();
    const [isClicked, setIsClicked] = useState(false);

    // USEEFFECT
    useEffect(() => {
        if (firebaseUser && firebaseUser !== 'loading' && onAuthed) {
            onAuthed(firebaseUser);
        }
    }, [firebaseUser, onAuthed]);

    return (
        <MuiButton
            className={cx(c.root, className)}
            disabled={firebaseUser === 'loading' || !!firebaseUser || isClicked}
            onClick={() => {
                setIsClicked(true);
                signinWithGoogle({ projectName: 'default' });
            }}
        >
            {firebaseUser ? (
                <SvgCheck className={c.googleIcon} />
            ) : isClicked ? (
                <MuiCircularProgress className={c.progress} size={14} />
            ) : (
                <SvgGoogle className={c.googleIcon} />
            )}
            <span className={c.text}>{'Googleでログイン'}</span>
        </MuiButton>
    );
};
SignInWithGoogleButton.displayName = 'SignInWithGoogleButton';
