import { PresenceStates } from '@@models';

export const setPresenceStates = ({
    prev,
    update,
}: {
    prev: Loading<PresenceStates>;
    update: (prev: Loading<PresenceStates>) => PresenceStates;
}): PresenceStates => {
    const current = prev === 'loading' ? 'loading' : new PresenceStates({ ...prev.data });
    return update(current);
};
