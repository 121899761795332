import '@@styles/globals.css';
import { FC } from 'react';

import { FirebaseUser } from '@@firebase';
import { useFirebaseUserCtx } from '@@ctx';

import { Authorized } from './Authorized';
import { Dinied } from './Denied';
import { PreAuthenticated } from './PreAuthenticated';

const getIsAuthorized = (firebaseUser: Loading<FirebaseUser | undefined>) => {
    if (!firebaseUser || firebaseUser === 'loading' || !firebaseUser.email || !firebaseUser.emailVerified) {
        return false;
    }
    return firebaseUser.email.endsWith('@noshift.com') || firebaseUser.email.endsWith('@noshift.work');
};

export const WholePage: FC = () => {
    // CTX
    const { firebaseUser } = useFirebaseUserCtx();

    // DATA
    const isAuthorized = getIsAuthorized(firebaseUser);

    // console.log({ firebaseUser, isAuthorized });

    return (
        <div>
            {firebaseUser === 'loading' ? (
                'loading...'
            ) : !firebaseUser ? (
                <PreAuthenticated />
            ) : !isAuthorized ? (
                <Dinied />
            ) : (
                <Authorized />
            )}
        </div>
    );
};
WholePage.displayName = '@@construction/WholePage';
