import { useReducer } from 'react';

import type { Pins, Pin } from '@@models';

import { removePin } from './removePin';
import { setPin } from './setPin';
import { setPins } from './setPins';

export type DispatchPinsInput = Partial<{
    setPins: {
        update: (prev: Loading<Pins>) => Pins;
    };
    setPin: {
        key: string;
        update: (prev: Pin | undefined) => Pin;
    };
    removePin: {
        key: string;
    };
}>;
export type DispatchPins = (input: DispatchPinsInput) => void;

export const usePins = () => {
    // STATE
    const [pins, dispatchPins] = useReducer<(prev: Loading<Pins>, input: DispatchPinsInput) => Loading<Pins>>(
        (prev, input) => {
            try {
                if (input.setPins) {
                    return setPins({
                        prev,
                        ...input.setPins,
                    });
                }
                if (prev === 'loading') {
                    throw new Error('usePins: prev === loading.');
                }
                if (input.setPin) {
                    return setPin({
                        prev,
                        ...input.setPin,
                    });
                }
                if (input.removePin) {
                    return removePin({
                        prev,
                        ...input.removePin,
                    });
                }
            } catch {
                return prev;
            }
            throw new Error('usePins: Unexpected.');
        },
        'loading'
    );

    return {
        pins,
        dispatchPins,
    };
};
