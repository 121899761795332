export class PresenceState {
    // SYSTEM
    readonly userId: string;
    readonly createdAt: Date;
    updatedAt: Date;

    // DATA
    isWatching: boolean;

    constructor({
        userId,
        createdAt,
        updatedAt,
        isWatching,
    }: {
        // SYSTEM
        readonly userId: string;
        readonly createdAt: Date;
        updatedAt: Date;

        // DATA
        isWatching: boolean;
    }) {
        this.userId = userId;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.isWatching = isWatching;
    }
}
