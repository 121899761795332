import { push } from 'firebase/database';

import { RdbMeasure, getRdbMeasureInput, getRdbMeasuresRef } from './RdbMeasure';

export const addRdbMeasure = ({
    domain,
    keywordText,
    text,
    userId,
}: Omit<RdbMeasure<undefined, Date>, 'createdAt' | 'updatedAt'> & {
    domain: string;
    keywordText: string;
}) => {
    const measureRef = getRdbMeasuresRef({ domain });
    push(
        measureRef,
        getRdbMeasureInput({
            createdAt: new Date(),
            updatedAt: new Date(),
            keywordText,
            text,
            userId,
        })
    );
};
