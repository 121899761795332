import { QueryAnalysis, ScTerm, ScOffset, ScQueryResultItem } from '../interfaces';
import { Keyword } from '../rawModels';
import { MeasureOptions, NoteOptions, Pins } from '../AssociativeModels';

export class KeywordOption {
    // DATA
    readonly keyword: Keyword;
    readonly scResults: {
        [term in ScTerm]?: {
            [offset in ScOffset]?: {
                primary: ScQueryResultItem;
                others: ScQueryResultItem[];
            };
        };
    };
    readonly analysis: QueryAnalysis | undefined;
    readonly measureOptions: MeasureOptions;
    readonly noteOptions: NoteOptions;
    readonly pins: Pins;

    constructor({
        keyword,
        scResults,
        analysis,
        measureOptions,
        noteOptions,
        pins,
    }: {
        // DATA
        readonly keyword: Keyword;
        readonly scResults: {
            [term in ScTerm]?: {
                [offset in ScOffset]?: {
                    primary: ScQueryResultItem;
                    others: ScQueryResultItem[];
                };
            };
        };
        readonly analysis: QueryAnalysis | undefined;
        readonly measureOptions: MeasureOptions;
        readonly noteOptions: NoteOptions;
        readonly pins: Pins;
    }) {
        this.keyword = keyword;
        this.scResults = scResults;
        this.analysis = analysis;
        this.measureOptions = measureOptions;
        this.noteOptions = noteOptions;
        this.pins = pins;
    }
}
