import {
    Keyword,
    ScQueryResultItem,
    QueryAnalysis,
    ScTerm,
    ScOffset,
    getWeeklyAmount,
    getScResultItem,
} from '@@models';

// const getMonthlyAmount = (weeklyAmount: number, inputTerm: ScTerm) => {
//     const termDays = inputTerm === '1w' ? 7 : inputTerm === '4w' ? 28 : inputTerm === '12w' ? 7 * 12 : -1;
//     if (termDays === -1) {
//         throw new Error(`getMonthlyAmount: unexpectedly got inputTerm:${inputTerm}`);
//     }
//     return weeklyAmount * (30 / termDays);
// };

export const getQueryAnalysis = ({
    keyword,
    scQueryResults,
    term,
}: {
    keyword: Keyword;
    scQueryResults: {
        [term in ScTerm]?: {
            [offset in ScOffset]?: {
                primary: ScQueryResultItem;
                others: ScQueryResultItem[];
            };
        };
    };
    term: ScTerm;
}): QueryAnalysis | undefined => {
    const current = getScResultItem({ scQueryResults, term, offset: 0 });
    const prev = getScResultItem({ scQueryResults, term, offset: 1 });
    if (!current || !prev) {
        return undefined;
    }
    // volume
    const termVolume = getWeeklyAmount({
        inputAmount: keyword.volume,
        inputTerm: '1m',
        targetTerm: term,
    });

    // current
    const currentCoverage = current.primary.clicks / termVolume;
    const currentLosingVolume = termVolume - current.primary.clicks;
    const currentScore =
        getWeeklyAmount({
            inputAmount: current.primary.clicks,
            inputTerm: term,
            targetTerm: term,
        }) / current.primary.position;

    // prev
    const prevCoverage = prev.primary.clicks / termVolume;
    const prevLosingVolume = termVolume - prev.primary.clicks;
    const prevScore =
        getWeeklyAmount({
            inputAmount: prev.primary.clicks,
            inputTerm: term,
            targetTerm: term,
        }) / prev.primary.position;

    // best
    const best = (() => {
        let bestCtr = prev.primary.ctr;
        let bestPosition = prev.primary.position;
        let bestCoverage = prevCoverage;
        let bestLosingVolume = prevLosingVolume;
        let bestScore = prevScore;
        (Object.keys(scQueryResults) as ScTerm[]).forEach((term) => {
            const offsets = scQueryResults[term];
            if (!offsets) {
                return;
            }
            Object.keys(scQueryResults[term] as string[]).forEach((offset_) => {
                const offset = Number(offset_) as ScOffset;
                if (term === '1w' && offset === 0) {
                    return;
                }
                const items = offsets[offset];
                if (!items) {
                    return;
                }
                const { ctr, position, clicks } = items.primary;
                const coverage = clicks / termVolume;
                const losingVolume = termVolume - clicks;
                const score =
                    getWeeklyAmount({
                        inputAmount: clicks,
                        inputTerm: term,
                        targetTerm: term,
                    }) / position;

                if (ctr > bestCtr) {
                    bestCtr = ctr;
                }
                if (position < bestPosition) {
                    bestPosition = position;
                }
                if (coverage > bestCoverage) {
                    bestCoverage = coverage;
                }
                if (losingVolume < bestLosingVolume) {
                    bestLosingVolume = losingVolume;
                }
                if (score > bestScore) {
                    bestScore = score;
                }
            });
        });
        return {
            bestCtr,
            bestPosition,
            bestCoverage,
            bestLosingVolume,
            bestScore,
        };
    })();

    return {
        coverage: currentCoverage,
        losingVolume: currentLosingVolume,
        score: currentScore,
        best: {
            coverage: best.bestCoverage,
            losingVolume: best.bestLosingVolume,
            score: best.bestScore,
            ctr: best.bestCtr,
            position: best.bestPosition,
        },
        prev: {
            coverage: prevCoverage,
            losingVolume: prevLosingVolume,
            score: prevScore,
            ctr: prev.primary.ctr,
            position: prev.primary.position,
        },
        vsBest: {
            coverage: currentCoverage - best.bestCoverage,
            losingVolume: currentLosingVolume - best.bestLosingVolume,
            score: currentScore - best.bestScore,
            ctr: current.primary.ctr - best.bestCtr,
            position: current.primary.position - best.bestPosition,
        },
        vsPrev: {
            coverage: currentCoverage - prevCoverage,
            losingVolume: currentLosingVolume - prevLosingVolume,
            score: currentScore - prevScore,
            ctr: current.primary.ctr - prev.primary.ctr,
            position: current.primary.position - prev.primary.position,
        },
        priority: 1, // later
    };
};
