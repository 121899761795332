import { Measure } from '../rawModels';

import { UserOption } from './UserOption';

export class MeasureOption {
    // DATA
    readonly measure: Measure;
    readonly userOption: UserOption;

    constructor({
        measure,
        userOption,
    }: {
        // DATA
        readonly measure: Measure;
        readonly userOption: UserOption;
    }) {
        this.measure = measure;
        this.userOption = userOption;
    }
}
