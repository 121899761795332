import { FC } from 'react';

import { createClassNames, cx } from '@@styles';
import { KeywordOption, getWeeklyAmount, getScResultItem } from '@@models';
import { useLocationCtx } from '@@ctx';

import { tableCellWidths } from '../tableCellWidths';
import { getScUrl } from './getScUrl';
import { ComparingView } from './ComparingView';
import { MeasuresPart } from './MeasuresPart';
import { NotesPart } from './NotesPart';
import { PinPart } from './PinPart';

const c = createClassNames({
    root: {
        'display': 'flex',
        'fontSize': 14,
        'position': 'relative',
        'width': 'fit-content',
        '&:not(:last-child)': {
            borderBottom: 'solid 1px #eee',
        },
        '&:hover': {
            background: '#fafafa',
        },
        '&.KeywordRow-root-expanded': {
            'background': '#f8f8f8',
            '&:hover': {
                background: '#f8f8f8',
            },
        },
    },
    cell: {
        'boxSizing': 'border-box',
        'flexGrow': 0,
        'flexShrink': 0,
        'padding': 7,
        '&:not(:last-child)': {
            // marginRight: 14,
            borderRight: 'solid 1px #eee',
        },
    },
    category: {
        display: 'block',
        fontSize: 12,
    },
    scResultPartPlaceHolder: {},
    scorePartPlaceHolder: {},
});

const p = createClassNames({
    pin: {
        padding: 0,
    },
    categories: {},
    volume: {
        textAlign: 'right',
        color: '#aaa',
    },
    query: {
        'position': 'sticky',
        'left': 0,
        'zIndex': 1,
        '.KeywordRow-root &': {
            'background': 'rgba(255,255,255,.9)',
            '&:hover': {
                background: 'rgba(250,250,250,.9)',
            },
        },
        '.KeywordRow-root-expanded &': {
            'background': 'rgba(248,248,248,.9)',
            '&:hover': {
                background: 'rgba(248,248,248,.9)',
            },
        },
    },
    queryAnchor: {
        'textDecoration': 'none',
        'color': '#000',
        '&:visited': {
            color: '#681da8',
        },
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    page: {
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-all',
        fontSize: 12,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    pageAnchor: {
        'textDecoration': 'none',
        'color': '#888',
        '&:visited': {
            color: '#8454ac',
        },
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    // scLink: {
    //     borderRadius: 2,
    //     fontSize: 12,
    //     padding: '1px 4px',
    //     background: '#fafafa',
    // },
    scLinkAnchor: {
        'display': 'block',
        'whiteSpace': 'nowrap',
        'marginLeft': 14,
        'fontSize': 10,
        'textDecoration': 'none',
        'color': '#555',
        // '&:visited': {
        //     color: '#b49fdc',
        // },
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    position: {
        cursor: 'pointer',
    },
    ctr: {
        cursor: 'pointer',
    },
    score: {},
    clicks: {
        textAlign: 'right',
    },
    impressions: {
        textAlign: 'right',
    },
    coverage: {
        textAlign: 'right',
    },
    losingVolume: {
        textAlign: 'right',
    },
    priority: {
        textAlign: 'right',
    },
    measures: {},
    notes: {},
});

export const KeywordRow: FC<{
    className?: string;
    keywordOption: KeywordOption;
    isExpanded: boolean;
    onClickExpand: () => void;
}> = ({ className, keywordOption, isExpanded, onClickExpand }) => {
    // CTX
    const { term } = useLocationCtx();

    // DATA
    const currentPrimary = getScResultItem({
        scQueryResults: keywordOption.scResults,
        term,
        offset: 0,
    })?.primary;
    const prevPrimary = getScResultItem({
        scQueryResults: keywordOption.scResults,
        term,
        offset: 1,
    })?.primary;

    return (
        <div
            className={cx(
                c.root,
                'KeywordRow-root',
                {
                    'KeywordRow-root-expanded': isExpanded,
                },
                className
            )}
        >
            <PinPart
                className={cx(c.cell, p.pin)}
                style={{ width: tableCellWidths.pin }}
                keywordOption={keywordOption}
            />
            <div className={cx(c.cell, p.categories)} style={{ width: tableCellWidths.categories }}>
                {keywordOption.keyword.categories.map((category) => (
                    <span key={category} className={c.category}>
                        {category}
                    </span>
                ))}
            </div>
            <div className={cx(c.cell, p.volume)} style={{ width: tableCellWidths.volume }}>
                {getWeeklyAmount({
                    inputAmount: keywordOption.keyword.volume,
                    inputTerm: '1m',
                    targetTerm: term,
                }).toFixed(0)}
            </div>
            <div className={cx(c.cell, p.query)} style={{ width: tableCellWidths.query }}>
                <a
                    className={p.queryAnchor}
                    href={`https://www.google.com/search?q=${keywordOption.keyword.query.replace(/\s/g, '+')}`}
                    target={'_blank'}
                    rel={'nofollow noopener noreferrer'}
                >
                    {keywordOption.keyword.query}
                </a>
            </div>
            <div className={cx(c.cell, p.page)} style={{ width: tableCellWidths.page }}>
                {currentPrimary?.url ? (
                    <a
                        className={p.pageAnchor}
                        href={`https://www.noshift.com/${currentPrimary.url}`}
                        target={'_blank'}
                        rel={'nofollow noopener noreferrer'}
                    >
                        {currentPrimary.url}
                    </a>
                ) : (
                    '-'
                )}
                <a
                    className={p.scLinkAnchor}
                    target={'_blank'}
                    rel={'nofollow noopener noreferrer'}
                    href={getScUrl({
                        domain: 'noshift.com',
                        query: keywordOption.keyword.query,
                        term,
                        offset: 0,
                    })}
                >
                    {'SC'}
                </a>
            </div>
            {/* <div className={cx(c.cell, p.scLink)} style={{ width: tableCellWidths.scLink }}>
                <a
                    className={p.scLinkAnchor}
                    target={'_blank'}
                    rel={'nofollow noopener noreferrer'}
                    href={getScUrl({
                        domain: 'noshift.com',
                        query: keywordOption.keyword.query,
                    })}
                >
                    {'SC'}
                </a>
            </div> */}
            {currentPrimary && prevPrimary && keywordOption.analysis ? (
                <ComparingView
                    className={cx(c.cell, p.position)}
                    style={{ width: tableCellWidths.position }}
                    rightWidth={60}
                    prev={prevPrimary.position}
                    current={currentPrimary.position}
                    best={keywordOption.analysis.best.position}
                    vsPrev={keywordOption.analysis.vsPrev.position}
                    vsBest={keywordOption.analysis.vsBest.position}
                    positiveDirection={'down'}
                    fixer={(position) => position.toFixed(2)}
                    letterForPositive={'↑'}
                    transition={{
                        isExpanded,
                        value: {
                            '1w': {
                                0: keywordOption.scResults['1w']?.[0]?.primary.position,
                                1: keywordOption.scResults['1w']?.[1]?.primary.position,
                                2: keywordOption.scResults['1w']?.[2]?.primary.position,
                                3: keywordOption.scResults['1w']?.[3]?.primary.position,
                            },
                            '4w': {
                                0: keywordOption.scResults['4w']?.[0]?.primary.position,
                                1: keywordOption.scResults['4w']?.[1]?.primary.position,
                                2: keywordOption.scResults['4w']?.[2]?.primary.position,
                                3: keywordOption.scResults['4w']?.[3]?.primary.position,
                            },
                            '12w': {
                                0: keywordOption.scResults['12w']?.[0]?.primary.position,
                                1: keywordOption.scResults['12w']?.[1]?.primary.position,
                                2: keywordOption.scResults['12w']?.[2]?.primary.position,
                                3: keywordOption.scResults['12w']?.[3]?.primary.position,
                            },
                        },
                        termStyle: {
                            width: 140,
                        },
                        valueStyle: {
                            width: 30,
                        },
                    }}
                    onClick={onClickExpand}
                />
            ) : (
                <div className={c.scResultPartPlaceHolder} style={{ width: tableCellWidths.position }} />
            )}
            {currentPrimary && prevPrimary && keywordOption.analysis ? (
                <ComparingView
                    className={cx(c.cell, p.ctr)}
                    style={{ width: tableCellWidths.ctr }}
                    rightWidth={70}
                    prev={prevPrimary.ctr}
                    current={currentPrimary.ctr}
                    best={keywordOption.analysis.best.ctr}
                    vsPrev={keywordOption.analysis.vsPrev.ctr}
                    vsBest={keywordOption.analysis.vsBest.ctr}
                    positiveDirection={'up'}
                    fixer={(ctr, kind) => `${(ctr * 100).toFixed(2)}${kind === 'diff' ? 'p' : '%'}`}
                    letterForPositive={'↑'}
                    transition={{
                        isExpanded,
                        value: {
                            '1w': {
                                0: keywordOption.scResults['1w']?.[0]?.primary.ctr,
                                1: keywordOption.scResults['1w']?.[1]?.primary.ctr,
                                2: keywordOption.scResults['1w']?.[2]?.primary.ctr,
                                3: keywordOption.scResults['1w']?.[3]?.primary.ctr,
                            },
                            '4w': {
                                0: keywordOption.scResults['4w']?.[0]?.primary.ctr,
                                1: keywordOption.scResults['4w']?.[1]?.primary.ctr,
                                2: keywordOption.scResults['4w']?.[2]?.primary.ctr,
                                3: keywordOption.scResults['4w']?.[3]?.primary.ctr,
                            },
                            '12w': {
                                0: keywordOption.scResults['12w']?.[0]?.primary.ctr,
                                1: keywordOption.scResults['12w']?.[1]?.primary.ctr,
                                2: keywordOption.scResults['12w']?.[2]?.primary.ctr,
                                3: keywordOption.scResults['12w']?.[3]?.primary.ctr,
                            },
                        },
                        termStyle: {
                            width: 140,
                        },
                        valueStyle: {
                            width: 30,
                        },
                    }}
                    onClick={onClickExpand}
                />
            ) : (
                <div className={c.scResultPartPlaceHolder} style={{ width: tableCellWidths.ctr }} />
            )}
            <div className={cx(c.cell, p.clicks)} style={{ width: tableCellWidths.clicks }}>
                {currentPrimary ? currentPrimary.clicks : '-'}
            </div>
            <div className={cx(c.cell, p.impressions)} style={{ width: tableCellWidths.impressions }}>
                {currentPrimary ? currentPrimary.impressions : '-'}
            </div>
            {keywordOption.analysis ? (
                <ComparingView
                    className={cx(c.cell, p.score)}
                    style={{ width: tableCellWidths.score }}
                    rightWidth={70}
                    prev={keywordOption.analysis.prev.score}
                    current={keywordOption.analysis.score}
                    best={keywordOption.analysis.best.score}
                    vsPrev={keywordOption.analysis.vsPrev.score}
                    vsBest={keywordOption.analysis.vsBest.score}
                    positiveDirection={'up'}
                    fixer={(score) => score.toFixed(2)}
                    letterForPositive={'↑'}
                />
            ) : (
                <div className={c.scorePartPlaceHolder} style={{ width: tableCellWidths.score }} />
            )}
            <div className={cx(c.cell, p.losingVolume)} style={{ width: tableCellWidths.losingVolume }}>
                {keywordOption.analysis?.losingVolume ? keywordOption.analysis.losingVolume.toFixed(0) : '-'}
            </div>
            {/* <div className={cx(c.cell, p.coverage)} style={{ width: tableCellWidths.coverage }}>
                {keywordOption.analysis?.coverage ? `${(keywordOption.analysis.coverage * 100).toFixed(0)}%` : '-'}
            </div> */}
            {/* <div className={cx(c.cell, p.priority)} style={{ width: tableCellWidths.priority }}>
                {keywordOption.analysis?.priority || '-'}
            </div> */}
            <MeasuresPart
                className={cx(c.cell, p.measures)}
                style={{ width: tableCellWidths.measures }}
                keywordOption={keywordOption}
            />
            <NotesPart
                className={cx(c.cell, p.notes)}
                style={{ width: tableCellWidths.notes }}
                keywordOption={keywordOption}
            />
        </div>
    );
};
KeywordRow.displayName = '@@construction/WholePage/Authorized/KeywordsTable/ChunkRow/KeywordRow';
