import { createTheme } from '@mui/material';

const fontFamilyBase = [
    'Avenir Next',
    'Avenir',
    'Noto Sans JP',
    '-apple-system',
    'メイリオ',
    'Meiryo',
    'Hiragino Kaku Gothic Pro',
    'Hiragino Kaku Gothic ProN',
    'Yu Gothic',
    'sans-serif',
].join(',');

const fontFamilyBold = [
    'LL Circular Black Sub',
    'Avenir Next',
    'Avenir',
    'Noto Sans JP',
    '-apple-system',
    'メイリオ',
    'Meiryo',
    'Hiragino Kaku Gothic Pro',
    'Hiragino Kaku Gothic ProN',
    'Yu Gothic',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
].join(',');

export const fontFamilyContent = [
    // 'Avenir Next',
    // 'Avenir',
    '-apple-system',
    'メイリオ',
    'Meiryo',
    'Hiragino Kaku Gothic Pro',
    'Hiragino Kaku Gothic ProN',
    'Yu Gothic',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
];

export const theme = createTheme({
    palette: {
        primary: {
            main: '#ffb000',
        },
        secondary: {
            main: '#ffb000',
        },
        error: {
            main: '#ff1744',
        },
        warning: {
            main: '#FFD338',
        },
        info: {
            main: '#ddd',
        },
        success: {
            main: 'rgb(132,232,137)',
        },
        background: {
            default: '#fff',
        },
        text: {
            primary: 'rgba(0, 0, 0, .65)',
            secondary: 'rgba(0, 0, 0, .20)',
            disabled: 'rgba(0, 0, 0, .20)',
            // paper
        },
        divider: 'rgba(0, 0, 0, 0.1)',
        // action
    },
    typography: {
        fontFamily: fontFamilyBase,
        h1: {
            fontFamily: fontFamilyBold,
            fontSize: '2rem',
            fontWeight: 'bold',
        },
        h2: {
            fontFamily: fontFamilyBold,
            fontSize: '2rem',
            fontWeight: 'bold',
        },
        h3: {
            fontFamily: fontFamilyBold,
            fontSize: '2rem',
            fontWeight: 'bold',
        },
        h4: {
            fontFamily: fontFamilyBold,
            fontSize: '0.9rem',
            fontWeight: 'bold',
        },
        h5: {
            fontFamily: fontFamilyBold,
            fontSize: '0.9rem',
            fontWeight: 'bold',
        },
        h6: {
            fontFamily: fontFamilyBold,
            fontSize: '0.9rem',
            fontWeight: 'bold',
        },
        subtitle1: {
            fontFamily: fontFamilyBold,
            fontWeight: 'bold',
        },
        button: {
            textTransform: 'none',
        },
        body1: {
            fontSize: 15,
        },
        caption: {
            fontSize: 12,
        },
    },
    // props: {
    //     MuiTextField: {
    //         variant: 'outlined',
    //     },
    // },
    spacing: 14,
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    // color: 'inherit',
                    // '&:hover': {
                    //     backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    // },
                },
            },
        },
    },
});
