import { FC } from 'react';

import { SignInWithGoogleButton } from './SignInWithGoogleButton';

export const PreAuthenticated: FC = () => {
    return (
        <div>
            <div>sign in required</div>
            <SignInWithGoogleButton />
        </div>
    );
};
PreAuthenticated.displayName = '@@construction/WholePage/PreAuthenticated';
