import { ScTerm, ScOffset, ScQueryResult, ScQueryResultItem, ScQueryResults } from '@@models';
import { getDate } from '@@utils';

import { getCsScQueryResult } from '../CsModels';

export const getScResults = async ({
    domain,
    fetchDate,
}: {
    domain: string;
    fetchDate: Date;
}): Promise<ScQueryResults> => {
    const getResult = async ({ term, offset }: { term: ScTerm; offset: ScOffset }) => {
        return {
            term,
            offset,
            result:
                (await getCsScQueryResult({
                    domain,
                    fetchDate,
                    term,
                    offset,
                })) ||
                (await getCsScQueryResult({
                    domain,
                    fetchDate: getDate(-1, fetchDate), // ない場合、昨日の分をfetch
                    term,
                    offset,
                })),
        };
    };
    const results = await Promise.all([
        getResult({
            term: '1w',
            offset: 0,
        }),
        getResult({
            term: '1w',
            offset: 1,
        }),
        getResult({
            term: '1w',
            offset: 2,
        }),
        getResult({
            term: '1w',
            offset: 3,
        }),
        getResult({
            term: '4w',
            offset: 0,
        }),
        getResult({
            term: '4w',
            offset: 1,
        }),
        getResult({
            term: '4w',
            offset: 2,
        }),
        getResult({
            term: '4w',
            offset: 3,
        }),
        getResult({
            term: '12w',
            offset: 0,
        }),
        getResult({
            term: '12w',
            offset: 1,
        }),
        getResult({
            term: '12w',
            offset: 2,
        }),
        getResult({
            term: '12w',
            offset: 3,
        }),
    ]);
    // console.log({ results });

    return new ScQueryResults(
        results.reduce(
            (acc, { term, offset, result }) => {
                if (!result) {
                    return acc;
                }
                const scQueryResult = new ScQueryResult({
                    createdAt: fetchDate, // 正確にはメタデータを利用すべきだが、どうでもいいので
                    term,
                    fetchDate,
                    offset,
                    results: Object.keys(result).reduce(
                        (acc, keywordText) => {
                            const items = result[keywordText]!.map(
                                ({ keys, clicks, ctr, position, impressions }): ScQueryResultItem => {
                                    const url = keys[0]!;
                                    return {
                                        url,
                                        clicks,
                                        ctr,
                                        position,
                                        impressions,
                                    };
                                }
                            );
                            const [primary, ...others] = items.sort((a, b) => (a.impressions > b.impressions ? -1 : 1));
                            if (!primary) {
                                return acc;
                            }
                            return {
                                ...acc,
                                [keywordText]: {
                                    primary,
                                    others,
                                },
                            };
                        },
                        {} as {
                            [keywordText: string]: {
                                primary: ScQueryResultItem;
                                others: ScQueryResultItem[];
                            };
                        }
                    ),
                });
                return {
                    ...acc,
                    [term]: {
                        ...acc[term],
                        [offset]: scQueryResult,
                    },
                };
            },
            {} as {
                [term in ScTerm]?: {
                    [offset in ScOffset]?: ScQueryResult;
                };
            }
        )
    );
};
