export const getYYYYMMDD = (date: Date, boundary?: string) => {
    return `${date.getFullYear()}${boundary || ''}${`00${String(date.getMonth() + 1)}`.slice(-2)}${
        boundary || ''
    }${`00${String(date.getDate())}`.slice(-2)}`;
};

export const parseFromYYYYMMDD = (yyyymmdd: string, boundary?: string) => {
    const [yyyy, mm, dd] = yyyymmdd.split(boundary || '');
    if (yyyy && mm && dd) {
        return new Date(Number(yyyy), Number(mm) - 1, Number(dd));
    }
    throw new Error(`parseFromYYYYMMDD: faild to parse ${yyyymmdd} (boundary: "${boundary || ''}")`);
};
