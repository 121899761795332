import { onChildRemoved, onChildAdded, onChildChanged } from 'firebase/database';

import { User } from '@@models';

import { getRdbUsersRef, getRdbUserFromSource } from '../RdbModels';
import { getRdbData } from '../utils';

export const listenToUsers = ({
    onAdded,
    onChanged,
    onRemoved,
}: {
    onAdded: (addedUser: User) => void;
    onChanged: (changedUser: User) => void;
    onRemoved: (removedUserKey: string) => void;
}) => {
    const usersRef = getRdbUsersRef();

    const unsubscribeOnChildAdded = onChildAdded(usersRef, (data) => {
        const rdbUser = getRdbData(data, getRdbUserFromSource);
        if (rdbUser) {
            onAdded(new User(rdbUser));
        }
    });
    const unsubscribeOnChildChanged = onChildChanged(usersRef, (data) => {
        const rdbUser = getRdbData(data, getRdbUserFromSource);
        if (rdbUser) {
            onChanged(new User(rdbUser));
        }
    });
    const unsubscribeOnChildRemoved = onChildRemoved(usersRef, (data) => {
        if (data.key) {
            onRemoved(data.key);
        }
    });

    return {
        unsubscribeListenToUsers: () => {
            unsubscribeOnChildAdded();
            unsubscribeOnChildChanged();
            unsubscribeOnChildRemoved();
        },
    };
};
