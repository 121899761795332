import { onChildRemoved, onChildAdded, onChildChanged } from 'firebase/database';

import { Pin, parsePinIdentifier } from '@@models';

import { getRdbPinsRef, getRdbPinFromSource } from '../RdbModels';
import { getRdbData } from '../utils';

export const listenToPins = ({
    domain,
    onAdded,
    onChanged,
    onRemoved,
}: {
    domain: string;
    onAdded: (addedPin: Pin, key: string) => void;
    onChanged: (changedPin: Pin, key: string) => void;
    onRemoved: (removedPinKey: string) => void;
}) => {
    const pinsRef = getRdbPinsRef({ domain });

    const unsubscribeOnChildAdded = onChildAdded(pinsRef, (data) => {
        const rdbPin = getRdbData(data, getRdbPinFromSource);
        if (rdbPin) {
            const { keywordText, userId } = parsePinIdentifier({ pinIdentifier: rdbPin.id });
            const pin = new Pin({ ...rdbPin, keywordText, userId });
            onAdded(pin, rdbPin.id);
        }
    });
    const unsubscribeOnChildChanged = onChildChanged(pinsRef, (data) => {
        const rdbPin = getRdbData(data, getRdbPinFromSource);
        if (rdbPin) {
            const { keywordText, userId } = parsePinIdentifier({ pinIdentifier: rdbPin.id });
            const pin = new Pin({ ...rdbPin, keywordText, userId });
            onChanged(pin, rdbPin.id);
        }
    });
    const unsubscribeOnChildRemoved = onChildRemoved(pinsRef, (data) => {
        if (data.key) {
            onRemoved(data.key);
        }
    });

    return {
        unsubscribeListenToPins: () => {
            unsubscribeOnChildAdded();
            unsubscribeOnChildChanged();
            unsubscribeOnChildRemoved();
        },
    };
};
