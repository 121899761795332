import { getRdbRef } from '@@firebase';

// REF
export const getRdbUsersRef = () => {
    return getRdbRef({
        projectName: 'default',
        path: 'users', // ひとまずは内部用なのでこれでいいことにする (bridge modelをつくるのは手間なので)
    });
};
export const getRdbUserRef = ({ userId }: { userId: string }) => {
    return getRdbRef({
        projectName: 'default',
        path: `users/${userId}`,
    });
};

// TYPE
export type RdbUser<TUndefined extends undefined | null = undefined, TDate extends Date | number = Date> = {
    // KNOWN: id

    // SYSTEM
    createdAt: TDate;
    updatedAt: TDate;

    // DATA
    uniqueName: string;
    displayName: string | TUndefined;
    color: {
        r: number;
        g: number;
        b: number;
    };
};

export const getRdbUser = ({
    createdAt,
    updatedAt,
    uniqueName,
    displayName,
    color: { r, g, b },
}: RdbUser<undefined, Date>): RdbUser<undefined, Date> => {
    return {
        createdAt,
        updatedAt,
        uniqueName,
        displayName,
        color: { r, g, b },
    };
};

export const getRdbUserInput = ({
    createdAt,
    updatedAt,
    uniqueName,
    displayName,
    color: { r, g, b },
}: RdbUser<undefined, Date>): RdbUser<null, number> => {
    return {
        createdAt: createdAt.getTime(),
        updatedAt: updatedAt.getTime(),
        uniqueName,
        displayName: displayName || null,
        color: { r, g, b },
    };
};

export const getRdbUserFromSource = ({
    createdAt,
    updatedAt,
    uniqueName,
    displayName,
    color: { r, g, b },
}: RdbUser<undefined, number>): RdbUser<undefined, Date> => {
    return {
        createdAt: new Date(createdAt),
        updatedAt: new Date(updatedAt),
        uniqueName,
        displayName,
        color: { r, g, b },
    };
};
