import { getDate } from '@@utils';

import { ScTerm, getWeeklyAmount, Category, getScResultItem, serializePinIdentifier } from '../interfaces';
import { KeywordOption } from '../Options/KeywordOption';
import { AssociativeModelBase } from './_AssociativeModelBase';

export class KeywordOptions extends AssociativeModelBase<KeywordOption> {
    getArrayByClicks({ term }: { term: ScTerm }): KeywordOption[] {
        return this.map((_, item) => item).sort((a_, b_) => {
            const a = getScResultItem({ scQueryResults: a_.scResults, term, offset: 0 })?.primary.clicks;
            const b = getScResultItem({ scQueryResults: b_.scResults, term, offset: 0 })?.primary.clicks;
            if ((a || 0) > (b || 0)) {
                return -1;
            }
            return 1;
        });
    }
    getArrayByImpressions({ term }: { term: ScTerm }): KeywordOption[] {
        return this.map((_, item) => item).sort((a_, b_) => {
            const a = getScResultItem({ scQueryResults: a_.scResults, term, offset: 0 })?.primary.impressions;
            const b = getScResultItem({ scQueryResults: b_.scResults, term, offset: 0 })?.primary.impressions;
            if ((a || 0) > (b || 0)) {
                return -1;
            }
            return 1;
        });
    }
    getArrayByPriority(): KeywordOption[] {
        return this.map((_, item) => item).sort((a_, b_) => {
            const a = a_.analysis?.priority;
            const b = b_.analysis?.priority;
            if ((a || 0) > (b || 0)) {
                return -1;
            }
            return 1;
        });
    }
    getArrayByLosingVolume(): KeywordOption[] {
        return this.map((_, item) => item).sort((a_, b_) => {
            const a = a_.analysis?.losingVolume;
            const b = b_.analysis?.losingVolume;
            if ((a || 0) > (b || 0)) {
                return -1;
            }
            return 1;
        });
    }
    getArrayByPosition({ term }: { term: ScTerm }): KeywordOption[] {
        return this.map((_, item) => item).sort((a_, b_) => {
            const a = a_.scResults[term]?.[0]?.primary.position;
            const b = b_.scResults[term]?.[0]?.primary.position;
            if (!a) {
                return 1;
            }
            if (!b) {
                return -1;
            }
            if (a < b) {
                return -1;
            }
            return 1;
        });
    }
    getArrayByScore(): KeywordOption[] {
        return this.map((_, item) => item).sort((a_, b_) => {
            const a = a_.analysis?.score;
            const b = b_.analysis?.score;
            if ((a || 0) > (b || 0)) {
                return -1;
            }
            return 1;
        });
    }
    getArrayByCTR({ term }: { term: ScTerm }): KeywordOption[] {
        return this.map((_, item) => item).sort((a_, b_) => {
            const a = getScResultItem({ scQueryResults: a_.scResults, term, offset: 0 })?.primary.ctr;
            const b = getScResultItem({ scQueryResults: b_.scResults, term, offset: 0 })?.primary.ctr;
            // if (!a) {
            //     return 1;
            // }
            // if (!b) {
            //     return -1;
            // }
            if ((a || 0) > (b || 0)) {
                return -1;
            }
            return 1;
        });
    }

    filterByMeasure(ignoringDays: number) {
        const ignoringDate = getDate(ignoringDays);
        const [noMeasures, hasMeasures] = this.filterWithRest(
            (_, item) =>
                !item.measureOptions.find((__, measureOption) => measureOption.measure.createdAt > ignoringDate)
        );
        return {
            noMeasures: new KeywordOptions(noMeasures.data),
            hasMeasures: new KeywordOptions(hasMeasures.data),
        };
    }
    filterByVolume({ orOver, term }: { orOver: number; term: ScTerm }) {
        return new KeywordOptions(
            this.filter((_, item) => {
                const keywordWeeklyVolume = getWeeklyAmount({
                    inputAmount: item.keyword.volume,
                    inputTerm: '1m',
                    targetTerm: term,
                });
                return keywordWeeklyVolume >= orOver;
            }).data
        );
    }
    filterByWord({ word }: { word: string }) {
        return new KeywordOptions(this.filter((_, item) => item.keyword.query.includes(word)).data);
    }
    filterByCategory({ category }: { category: Category }) {
        return new KeywordOptions(this.filter((_, item) => item.keyword.categories.includes(category)).data);
    }

    filterBulk({
        userId,
        measureDays,
        volume,
        word,
        category,
    }: {
        userId: string;
        measureDays: number;
        volume: Optional<{ orOver: number; term: ScTerm }>;
        word: string | undefined;
        category: Category | undefined;
    }) {
        const measureDate = getDate(measureDays);
        const { withPins, noMeasures, hasMeasures } = this.reduce(
            (acc, keywordText, item) => {
                const isVolumeOk = (() => {
                    if (volume) {
                        const keywordWeeklyVolume = getWeeklyAmount({
                            inputAmount: item.keyword.volume,
                            inputTerm: '1m',
                            targetTerm: volume.term,
                        });
                        return keywordWeeklyVolume >= volume.orOver;
                    }
                    return true;
                })();
                const isWordOk = (() => {
                    if (word) {
                        return item.keyword.query.includes(word);
                    }
                    return true;
                })();
                const isCategoryOk = (() => {
                    if (category) {
                        return item.keyword.categories.includes(category);
                    }
                    return true;
                })();
                if (isVolumeOk && isWordOk && isCategoryOk) {
                    // Pinの有無
                    if (item.pins.data[serializePinIdentifier({ keywordText, userId })]) {
                        return {
                            ...acc,
                            withPins: {
                                ...acc.withPins,
                                [keywordText]: item,
                            },
                        };
                    }
                    // 期限内のMeasureの有無
                    if (
                        !item.measureOptions.find((__, measureOption) => measureOption.measure.createdAt > measureDate)
                    ) {
                        return {
                            ...acc,
                            noMeasures: {
                                ...acc.noMeasures,
                                [keywordText]: item,
                            },
                        };
                    }
                    return {
                        ...acc,
                        hasMeasures: {
                            ...acc.hasMeasures,
                            [keywordText]: item,
                        },
                    };
                }
                return acc;
            },
            { withPins: {}, noMeasures: {}, hasMeasures: {} } as {
                withPins: KeywordOptions['data'];
                noMeasures: KeywordOptions['data'];
                hasMeasures: KeywordOptions['data'];
            }
        );
        return {
            withPins: new KeywordOptions(withPins),
            noMeasures: new KeywordOptions(noMeasures),
            hasMeasures: new KeywordOptions(hasMeasures),
        };
    }
}
