import { parse, stringify } from 'querystring';

export const retrieveSearchProp = ({ search, key }: { search: string; key: string }): string | undefined => {
    const value = parse(search.replace(/^\?/, ''))[key];
    if (Array.isArray(value)) {
        throw new Error(`parseSearch: got value:${value}.`);
    }
    return value;
};

export const updateSearch = ({
    search,
    key,
    value,
}: {
    search: string;
    key: string;
    value: string | undefined;
}): string | undefined => {
    const parsed = parse(search.replace(/^\?/, ''));
    if (!value) {
        delete parsed[key];
    } else {
        parsed[key] = value;
    }
    return `?${stringify(parsed)}`;
};
