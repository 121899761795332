import { Pins } from '@@models';
import { setPins } from './setPins';

export const removePin = ({ prev, key }: { prev: Pins; key: string }): Pins => {
    return setPins({
        prev,
        update: (prevPins) => {
            if (prevPins === 'loading') {
                throw new Error('removePin: prevPins === loading.');
            }
            const newPins = new Pins({ ...prev.data });
            newPins.remove(key);
            return newPins;
        },
    });
};
