import { useReducer } from 'react';

import type { Measures, Measure } from '@@models';

import { removeMeasure } from './removeMeasure';
import { setMeasure } from './setMeasure';
import { setMeasures } from './setMeasures';

export type DispatchMeasuresInput = Partial<{
    setMeasures: {
        update: (prev: Loading<Measures>) => Measures;
    };
    setMeasure: {
        key: string;
        update: (prev: Measure | undefined) => Measure;
    };
    removeMeasure: {
        key: string;
    };
}>;
export type DispatchMeasures = (input: DispatchMeasuresInput) => void;

export const useMeasures = () => {
    // STATE
    const [measures, dispatchMeasures] = useReducer<
        (prev: Loading<Measures>, input: DispatchMeasuresInput) => Loading<Measures>
    >((prev, input) => {
        try {
            if (input.setMeasures) {
                return setMeasures({
                    prev,
                    ...input.setMeasures,
                });
            }
            if (prev === 'loading') {
                throw new Error('useMeasures: prev === loading.');
            }
            if (input.setMeasure) {
                return setMeasure({
                    prev,
                    ...input.setMeasure,
                });
            }
            if (input.removeMeasure) {
                return removeMeasure({
                    prev,
                    ...input.removeMeasure,
                });
            }
        } catch {
            return prev;
        }
        throw new Error('useMeasures: Unexpected.');
    }, 'loading');

    return {
        measures,
        dispatchMeasures,
    };
};
