import { Keywords, Category, KeywordPriority } from '@@models';

import { keywordCategoryDefs } from './keywordCategoryDefs';
import { keywordPriorities } from './keywordPriorities';
import { keywordVolumesOver100 } from './keywordVolumesOver100';
import { keywordVolumesUnder100 } from './keywordVolumesUnder100';

export const keywordsOver100 = new Keywords(
    (Object.keys(keywordVolumesOver100) as (keyof typeof keywordVolumesOver100)[]).reduce(
        (acc, query) => ({
            ...acc,
            [query]: {
                keywordPriority: keywordPriorities[query] || 0,
                categories: keywordCategoryDefs[query] || [],
                query,
                volume: keywordVolumesOver100[query],
            },
        }),
        {} as {
            [query in keyof typeof keywordVolumesOver100]: {
                keywordPriority: KeywordPriority;
                categories: Category[];
                query: string;
                volume: number;
            };
        }
    )
);

export const keywordsUnder100 = new Keywords(
    (Object.keys(keywordVolumesUnder100) as (keyof typeof keywordVolumesUnder100)[]).reduce(
        (acc, query) => ({
            ...acc,
            [query]: {
                keywordPriority: 0,
                categories: [],
                query,
                volume: keywordVolumesUnder100[query],
            },
        }),
        {} as {
            [query in keyof typeof keywordVolumesUnder100]: {
                keywordPriority: KeywordPriority;
                categories: Category[];
                query: string;
                volume: number;
            };
        }
    )
);
