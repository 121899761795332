import { getWithRetry } from '@@firebase';
import { Note, Notes, parseNoteIdentifier } from '@@models';

import { getRdbDataMap } from '../utils';
import { getRdbNotesRef, getRdbNoteFromSource, RdbNote } from '../RdbModels';

export const getNotes = async ({ domain }: { domain: string }): Promise<Notes> => {
    const notesRef = getRdbNotesRef({ domain });
    const data = await getWithRetry(notesRef);
    const notes = getRdbDataMap(data, (key, props: RdbNote<undefined, number>) => {
        const { keywordText, userId } = parseNoteIdentifier({ noteIdentifier: key });
        return new Note({
            keywordText,
            userId,
            ...getRdbNoteFromSource(props),
        });
    });
    return new Notes(notes);
};
