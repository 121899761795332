import { css } from '@emotion/css';

export const createClassNames = <
    TInput extends {
        [className: string]: Parameters<typeof css>[0];
    }
>(
    classNames: TInput
): {
    [key in keyof TInput]: string;
} => {
    return Object.keys(classNames).reduce(
        (acc, className) => ({
            ...acc,
            [className]: css(classNames[className]!),
        }),
        {} as {
            [key in keyof TInput]: string;
        }
    );
};

export const createClassNamesGenerator = <
    TInput extends {
        [className: string]: (input: any) => Parameters<typeof css>[0];
    }
>(
    classNames: TInput
): {
    [key in keyof TInput]: (input: Parameters<TInput[key]>[0]) => string;
} => {
    return Object.keys(classNames).reduce(
        (acc, className) => ({
            ...acc,
            [className]: (input) => css(classNames[className]!(input)),
        }),
        {} as {
            [key in keyof TInput]: (input: Parameters<TInput[key]>[0]) => string;
        }
    );
};
