import { getUsers, listenToUsers } from '@@Rdb';
import { DispatchUsers } from '../useDomainData';

export const initUsers = async ({ dispatchUsers }: { dispatchUsers: DispatchUsers }) => {
    const users = await getUsers();
    dispatchUsers({
        setUsers: {
            update: () => users,
        },
    });

    const { unsubscribeListenToUsers } = listenToUsers({
        onAdded: (addedUser) => {
            dispatchUsers({
                setUser: {
                    key: addedUser.id,
                    update: () => addedUser,
                },
            });
        },
        onChanged: (changedUser) => {
            dispatchUsers({
                setUser: {
                    key: changedUser.id,
                    update: () => changedUser,
                },
            });
        },
        onRemoved: (removedKey) => {
            dispatchUsers({
                removeUser: {
                    key: removedKey,
                },
            });
        },
    });

    return unsubscribeListenToUsers;
};
