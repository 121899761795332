import { FC, CSSProperties, useState, useRef, useEffect, useCallback, KeyboardEvent } from 'react';

import MuiInputBase from '@mui/material/InputBase';

import { createClassNames, cx } from '@@styles';

const c = createClassNames({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    text: {
        // '& input:focus': {
        //     outline: 'solid 1px #000',
        // },
    },
    buttons: {
        display: 'flex',
        alignItems: 'center',
    },
    submitButton: {},
    cancelButton: {},
});

export const TextEditor: FC<{
    className?: string;
    style?: CSSProperties;
    text: string;
    onClose: () => void;
    placeholder?: string;
    submit: (text: string) => void;
}> = ({ className, style, text: givenText, onClose, placeholder, submit }) => {
    // REF
    const inputRef = useRef<HTMLInputElement | null>(null);

    // STATE
    const [text, setText] = useState<string>(givenText);
    const [isComposing, setIsComposing] = useState(false);

    // CALLBACK
    const handleKeydown = useCallback(
        (event: KeyboardEvent<HTMLDivElement>) => {
            if (event.key === 'Enter' && !isComposing) {
                event.preventDefault();
                // inputRef.current?.blur();
                submit(text);
                onClose();
            }
        },
        [isComposing, text, submit, onClose]
    );

    // FX
    useEffect(() => {
        inputRef.current?.focus();
    }, []);

    return (
        <div
            className={cx(c.root, className)}
            style={style}
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <MuiInputBase
                inputRef={inputRef}
                className={c.text}
                value={text || ''}
                onChange={(event) => {
                    setText(event.target.value || '');
                }}
                placeholder={placeholder}
                onBlur={onClose}
                onKeyDownCapture={handleKeydown}
                onCompositionStart={() => setIsComposing(true)}
                onCompositionEnd={() => setIsComposing(false)}
            />
            {/* <div className={c.buttons}>
                <button onClick={onClose} className={c.cancelButton}>
                    {'キャンセル'}
                </button>
                <button onClick={() => submit(text)} className={c.submitButton} disabled={!text || givenText === text}>
                    {'適用'}
                </button>
            </div> */}
        </div>
    );
};
TextEditor.displayName = '@@construction/WholePage/Authorized/KeywordsTable/TopMenu/TextEditor';
