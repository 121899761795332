import { KeywordPriority } from '@@models';

import { keywordVolumesOver100 } from './keywordVolumesOver100';

export type KeywordPriorities = {
    [keywordText in keyof typeof keywordVolumesOver100]?: KeywordPriority;
};

export const keywordPriorities: KeywordPriorities = {
    'ウーバーイーツ 始め 方': 1,
};
