import { onChildRemoved, onChildAdded, onChildChanged } from 'firebase/database';

import { Measure } from '@@models';

import { getRdbMeasuresRef, getRdbMeasureFromSource } from '../RdbModels';
import { getRdbData } from '../utils';

export const listenToMeasures = ({
    domain,
    onAdded,
    onChanged,
    onRemoved,
}: {
    domain: string;
    onAdded: (addedMeasure: Measure, key: string) => void;
    onChanged: (changedMeasure: Measure, key: string) => void;
    onRemoved: (removedMeasureId: string) => void;
}) => {
    const measuresRef = getRdbMeasuresRef({ domain });

    const unsubscribeOnChildAdded = onChildAdded(measuresRef, (data) => {
        const rdbMeasure = getRdbData(data, getRdbMeasureFromSource);
        if (rdbMeasure) {
            const measure = new Measure(rdbMeasure);
            onAdded(measure, rdbMeasure.id);
        }
    });
    const unsubscribeOnChildChanged = onChildChanged(measuresRef, (data) => {
        const rdbMeasure = getRdbData(data, getRdbMeasureFromSource);
        if (rdbMeasure) {
            const measure = new Measure(rdbMeasure);
            onChanged(measure, rdbMeasure.id);
        }
    });
    const unsubscribeOnChildRemoved = onChildRemoved(measuresRef, (data) => {
        if (data.key) {
            onRemoved(data.key);
        }
    });

    return {
        unsubscribeListenToMeasures: () => {
            unsubscribeOnChildAdded();
            unsubscribeOnChildChanged();
            unsubscribeOnChildRemoved();
        },
    };
};
