import { ScQueryResults, ScQueryResultItem, ScTerm, ScOffset } from '@@models';

export const getScQueryResultsForKeyword = ({
    keywordText,
    scQueryResults,
}: {
    keywordText: string;
    scQueryResults: ScQueryResults;
}): {
    [term in ScTerm]?: {
        [offset in ScOffset]?: {
            primary: ScQueryResultItem;
            others: ScQueryResultItem[];
        };
    };
} => {
    const getOffset = ({ term, offset }: { term: ScTerm; offset: ScOffset }) => {
        // const item = scQueryResults.data[term]?.[offset]?.results[keywordText];
        // if (!item) {
        //     console.log('getItem:', { scQueryResults, term, offset, keywordText, item });
        // }
        return scQueryResults.data[term]?.[offset]?.results[keywordText];
    };
    const getTerm = ({
        term,
    }: {
        term: ScTerm;
    }): {
        [offset in ScOffset]?: {
            primary: ScQueryResultItem;
            others: ScQueryResultItem[];
        };
    } => {
        return {
            0: getOffset({ term, offset: 0 }),
            1: getOffset({ term, offset: 1 }),
            2: getOffset({ term, offset: 2 }),
            3: getOffset({ term, offset: 3 }),
        };
    };
    return {
        '1w': getTerm({ term: '1w' }),
        '4w': getTerm({ term: '4w' }),
        '12w': getTerm({ term: '12w' }),
    };
};
