import { onValue } from 'firebase/database';

import { getConnectedRef } from './getConnectedRef';

export const listenToConnection = ({ onChange }: { onChange: (isConnected: boolean) => void }) => {
    const { connectedRef } = getConnectedRef({ projectName: 'default' });

    const unsubscribe = onValue(connectedRef, (data) => {
        const isConnected: boolean = data.val();
        onChange(isConnected);
    });

    return {
        unsubscribeConnection: unsubscribe,
    };
};
