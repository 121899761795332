export class Measure {
    // SYSTEM
    readonly id: string;
    readonly createdAt: Date;
    updatedAt: Date;

    // DATA
    keywordText: string;
    userId: string;
    text: string;

    constructor({
        id,
        createdAt,
        updatedAt,
        keywordText,
        userId,
        text,
    }: {
        // SYSTEM
        readonly id: string;
        readonly createdAt: Date;
        updatedAt: Date;

        // DATA
        keywordText: string;
        userId: string;
        text: string;
    }) {
        this.id = id;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.keywordText = keywordText;
        this.userId = userId;
        this.text = text;
    }
}
