import { set } from 'firebase/database';

import { RdbPresenceState, getRdbPresenceStateInput, getRdbPresenceStateRef } from './RdbPresenceState';

export const setRdbPresenceState = ({
    domain,
    userId,
    isWatching,
}: Omit<RdbPresenceState<undefined, Date>, 'createdAt' | 'updatedAt'> & {
    domain: string;
    userId: string;
}) => {
    const presenceStateRef = getRdbPresenceStateRef({ domain, userId });
    set(
        presenceStateRef,
        getRdbPresenceStateInput({
            createdAt: new Date(),
            updatedAt: new Date(),
            isWatching,
        })
    );
};
