import { User, PresenceState } from '../rawModels';

export class UserOption {
    // DATA
    readonly user: User;
    readonly presenceState: PresenceState | undefined;

    constructor({
        user,
        presenceState,
    }: {
        // DATA
        readonly user: User;
        readonly presenceState: PresenceState | undefined;
    }) {
        this.user = user;
        this.presenceState = presenceState;
    }
}
