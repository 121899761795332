import { useReducer } from 'react';

import type { ScQueryResults, ScQueryResult } from '@@models';

import { setScQueryResults } from './setScQueryResults';

export type DispatchScQueryResultsInput = Partial<{
    setScQueryResults: {
        update: (prev: Loading<ScQueryResults>) => ScQueryResults;
    };
}>;
export type DispatchScQueryResults = (input: DispatchScQueryResultsInput) => void;

export const useScQueryResults = () => {
    // STATE
    const [scQueryResults, dispatchScQueryResults] = useReducer<
        (prev: Loading<ScQueryResults>, input: DispatchScQueryResultsInput) => Loading<ScQueryResults>
    >((prev, input) => {
        try {
            if (input.setScQueryResults) {
                return setScQueryResults({
                    prev,
                    ...input.setScQueryResults,
                });
            }
            if (prev === 'loading') {
                throw new Error('useScQueryResults: prev === loading.');
            }
        } catch {
            return prev;
        }
        throw new Error('useScQueryResults: Unexpected.');
    }, 'loading');

    return {
        scQueryResults,
        dispatchScQueryResults,
    };
};
