export const serializeNoteIdentifier = ({ keywordText, userId }: { keywordText: string; userId: string }): string => {
    return `${keywordText}::${userId}`;
};

export const parseNoteIdentifier = ({
    noteIdentifier,
}: {
    noteIdentifier: string;
}): Errorable<{
    keywordText: string;
    userId: string;
}> => {
    const match = noteIdentifier.match(/^([^:]+)::(.+)$/);
    if (match) {
        const [_, keywordText, userId] = match;
        if (keywordText && userId) {
            return {
                keywordText,
                userId,
            };
        }
    }
    throw new Error(`parseNoteIdentifier: failed to parse ${noteIdentifier}.`);
};
