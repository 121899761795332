import { getRdbRef } from '@@firebase';

// REF
export const getRdbMeasuresRef = ({ domain }: { domain: string }) => {
    return getRdbRef({
        projectName: 'default',
        path: `measures/${domain}`,
    });
};
export const getRdbMeasureRef = ({ domain, id }: { domain: string; id: string }) => {
    return getRdbRef({
        projectName: 'default',
        path: `measures/${domain}/${id}`,
    });
};

// TYPE
export type RdbMeasure<_TUndefined extends undefined | null = undefined, TDate extends Date | number = Date> = {
    // KNOWN: domain, keywordText, id,

    // SYSTEM
    createdAt: TDate;
    updatedAt: TDate;

    // DATA
    keywordText: string;
    text: string;

    // RELATION
    userId: string;
};

export const getRdbMeasure = ({
    createdAt,
    updatedAt,
    keywordText,
    text,
    userId,
}: RdbMeasure<undefined, Date>): RdbMeasure<undefined, Date> => {
    return {
        createdAt,
        updatedAt,
        keywordText,
        text,
        userId,
    };
};

export const getRdbMeasureInput = ({
    createdAt,
    updatedAt,
    keywordText,
    text,
    userId,
}: RdbMeasure<undefined, Date>): RdbMeasure<null, number> => {
    return {
        createdAt: createdAt.getTime(),
        updatedAt: updatedAt.getTime(),
        keywordText,
        text,
        userId,
    };
};

export const getRdbMeasureFromSource = ({
    createdAt,
    updatedAt,
    keywordText,
    text,
    userId,
}: RdbMeasure<undefined, number>): RdbMeasure<undefined, Date> => {
    return {
        createdAt: new Date(createdAt),
        updatedAt: new Date(updatedAt),
        keywordText,
        text,
        userId,
    };
};
