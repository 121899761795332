import { ScTerm } from './ScQueryResult';

export const getWeeklyAmount = ({
    inputAmount,
    inputTerm,
    targetTerm,
}: {
    inputAmount: number;
    inputTerm: ScTerm | '1m';
    targetTerm: ScTerm;
}) => {
    const termDays =
        inputTerm === '1w' ? 7 : inputTerm === '4w' ? 28 : inputTerm === '12w' ? 7 * 12 : inputTerm === '1m' ? 30 : -1;
    if (termDays === -1) {
        throw new Error(`getWeeklyAmount: unexpectedly got inputTerm:${inputTerm}`);
    }
    const weeks = targetTerm === '1w' ? 1 : targetTerm === '4w' ? 4 : targetTerm === '12w' ? 12 : -1;
    if (weeks === -1) {
        throw new Error(`getWeeklyAmount: unexpectedly got targetTerm:${targetTerm}`);
    }
    return inputAmount * (7 / termDays) * weeks;
};
