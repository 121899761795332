import { FC } from 'react';

import { createClassNames } from '@@styles';
import { ScTerm, getTermDatesText, categories } from '@@models';
import { useLocationCtx, SortBy, sortBys } from '@@ctx';
import { ButtonWithMenu } from '@@coms/ButtonWithMenu';

import { TextMenu } from './TextMenu';
import { Menu } from './Menu';

const c = createClassNames({
    root: {
        position: 'fixed',
        width: '100vw',
        top: 0,
        // background: 'rgba(255,255,255,.9)',
        background: 'rgba(230,230,230,.9)',
        // color: '#fff',
        zIndex: 3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: 14,
        borderBottom: 'solid 1px #ddd',
        height: 50,
        padding: '0 14px',
        boxSizing: 'border-box',
    },
    left: {
        // flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
    },
    termAndSort: {},
    termAndSortButton: {
        marginRight: 7,
    },
    filters: {
        // background: '#ddd',
        padding: '0 7px',
        height: '100%',
        marginRight: 14,
        display: 'flex',
        alignItems: 'center',
    },
    filtersLabel: {
        fontSize: 12,
    },
    filtersContent: {
        display: 'flex',
        alignItems: 'center',
    },
    filterItem: {
        '&:not(:last-child)': {
            marginRight: 7,
        },
    },
});

const p = createClassNames({});

const getTermText = ({ term }: { term: ScTerm }) => {
    if (term === '1w') {
        return '１週間';
    }
    if (term === '4w') {
        return '４週間';
    }
    if (term === '12w') {
        return '12週間';
    }
    throw new Error(`getTermText: got unexpect term:${term}`);
};
const getSortByText = ({ sortBy }: { sortBy: SortBy }) => {
    if (sortBy === 'clicks') {
        return 'クリック数';
    }
    if (sortBy === 'impressions') {
        return '表示回数';
    }
    if (sortBy === 'ctr') {
        return 'CTR';
    }
    if (sortBy === 'losingVolume') {
        return '逃しているVol';
    }
    if (sortBy === 'position') {
        return '順位';
    }
    if (sortBy === 'score') {
        return 'スコア';
    }
    throw new Error(`getSortByText: got unexpect sortBy:${sortBy}`);
};

export const TopMenu: FC = () => {
    // CTX
    const {
        term,
        updateTerm,
        sortBy,
        updateSortBy,
        filterByCategory,
        updateFilterByCategory,
        filterByVolume,
        filterByWord,
        updateFilterByVolume,
        updateFilterByWord,
    } = useLocationCtx();

    return (
        <div className={c.root}>
            <div className={c.left}>
                <div className={c.termAndSort}>
                    <ButtonWithMenu
                        className={c.termAndSortButton}
                        menuItems={(['1w', '4w', '12w'] as ScTerm[]).map((item) => ({
                            text: `${getTermText({ term: item })} (${getTermDatesText({ term: item, offset: 0 })})`,
                            onClick: () => updateTerm(item),
                            isSelected: term === item,
                        }))}
                    >{`表示期間：${getTermText({ term })} (${getTermDatesText({
                        term,
                        offset: 0,
                    })})`}</ButtonWithMenu>
                    <ButtonWithMenu
                        className={c.termAndSortButton}
                        menuItems={sortBys.map((item) => ({
                            text: getSortByText({ sortBy: item }),
                            onClick: () => updateSortBy(item),
                            isSelected: sortBy === item,
                        }))}
                    >{`ソート：${getSortByText({ sortBy })}`}</ButtonWithMenu>
                </div>
                <div className={c.filters}>
                    {/* <span className={c.filtersLabel}>{'フィルタ'}</span> */}
                    <div className={c.filtersContent}>
                        <ButtonWithMenu
                            className={c.filterItem}
                            menuItems={[undefined, ...categories].map((item) => ({
                                text: item || '全て',
                                onClick: () => updateFilterByCategory(item),
                                isSelected: filterByCategory === item,
                            }))}
                        >{`カテゴリ：${filterByCategory || '全て'}`}</ButtonWithMenu>
                        <TextMenu
                            className={c.filterItem}
                            label={'Vol'}
                            text={!filterByVolume ? '' : String(filterByVolume)}
                            displayPlaceholder={'全て'}
                            editorPlaceholder={'Vol'}
                            onChange={(newText) => {
                                if (newText) {
                                    const numed = Number(newText);
                                    if (!isNaN(numed)) {
                                        updateFilterByVolume(numed);
                                    }
                                } else {
                                    updateFilterByVolume(undefined);
                                }
                            }}
                            postText={filterByVolume ? '以上' : undefined}
                        />
                        <TextMenu
                            className={c.filterItem}
                            label={'検索ワード'}
                            text={filterByWord}
                            displayPlaceholder={'全て'}
                            editorPlaceholder={'検索ワード'}
                            onChange={(word_) => {
                                const word = word_ ? word_.toLowerCase() : undefined;
                                updateFilterByWord(word);
                            }}
                            postText={filterByWord ? 'を含む' : undefined}
                        />
                    </div>
                </div>
            </div>
            <Menu />
        </div>
    );
};
TopMenu.displayName = '@@construction/WholePage/Authorized/KeywordsTable/TopMenu';
