import { getDateStr } from '@@utils';

import { ScTerm, ScOffset } from './ScQueryResult';

export const getStartAndEndDate = ({ term, offset }: { term: ScTerm; offset: ScOffset }) => {
    const days = term === '1w' ? 7 * 1 : term === '4w' ? 7 * 4 : term === '12w' ? 7 * 12 : 0; // 最後はunexpected.
    const offsetDays = offset * days;
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 7 - offsetDays - days);
    const endDate = new Date();
    endDate.setDate(endDate.getDate() - 7 - offsetDays);
    return {
        startDate,
        endDate,
    };
};

export const getTermDatesText = ({ term, offset }: { term: ScTerm; offset: ScOffset }) => {
    const { startDate, endDate } = getStartAndEndDate({ term, offset });
    const startDateText = getDateStr({ date: startDate, isNoYearForThisYear: true });
    const endDateText = getDateStr({ date: endDate, isNoYearForThisYear: true });
    return `${startDateText} ~ ${endDateText}`;
};
