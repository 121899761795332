import { DataSnapshot } from 'firebase/database';

export const getRdbDataMap = <TSource extends Record<string, any>, TRdbData extends Record<string, any>>(
    data: DataSnapshot,
    valueGetter: (key: string, props: TSource) => TRdbData,
    options?: {
        keyGetter?: (input: string) => string;
    }
) => {
    const value = data.val();
    if (value) {
        return Object.keys(value).reduce((acc, key) => {
            const item = value[key];
            if (item) {
                return {
                    ...acc,
                    [options?.keyGetter ? options.keyGetter(key) : key]: valueGetter(key, value[key]),
                };
            }
            return acc;
        }, {} as { [id: string]: TRdbData });
    }
    return {};
};
