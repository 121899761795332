import { FC, ReactNode, useEffect } from 'react';

import { FirebaseUserCtx } from './FirebaseUserCtx';
import { useFirebaseUser } from './useFirebaseUser';
import { useInitFirebaseUser } from './useInitFirebaseUser';

export const FirebaseUserCtxProvider: FC<{
    children: ReactNode;
}> = ({ children }) => {
    // STATE
    const { firebaseUser, setFirebaseUser } = useFirebaseUser();
    const { initFirebaseUser, unsubscribeFirebaseUser } = useInitFirebaseUser({
        firebaseUser,
        setFirebaseUser,
    });

    // FX
    useEffect(() => {
        initFirebaseUser({ refreshIntervalMin: 10 });
        return () => {
            unsubscribeFirebaseUser();
        };
    }, []);

    return (
        <FirebaseUserCtx.Provider
            value={{
                firebaseUser,
            }}
        >
            {children}
        </FirebaseUserCtx.Provider>
    );
};
FirebaseUserCtxProvider.displayName = 'FirebaseUserCtxProvider';
