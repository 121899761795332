import { getStartAndEndDate, ScTerm, ScOffset } from '@@models';
import { getYYYYMMDD } from '@@utils';

// export const getScUrl = ({ domain, query }: { domain: string; query: string }) => {
//     const base = 'https://search.google.com/search-console/performance/search-analytics';
//     const search = new URLSearchParams({
//         resource_id: `sc-domain:${domain}`,
//         metrics: 'CLICKS,IMPRESSIONS,CTR,POSITION',
//         num_of_days: '7',
//         breakdown: 'query',
//         query: `~^${query.replace(/\s/g, '\\s?')}$`,
//     });
//     return `${base}?${search.toString()}`;
// };

export const getScUrl = ({
    domain,
    query,
    term,
    offset,
}: {
    domain: string;
    query: string;
    term: ScTerm;
    offset: ScOffset;
}) => {
    const { startDate, endDate } = getStartAndEndDate({ term, offset });
    const base = 'https://search.google.com/search-console/performance/search-analytics';
    const search = new URLSearchParams({
        resource_id: `sc-domain:${domain}`,
        metrics: 'CLICKS,IMPRESSIONS,CTR,POSITION',
        start_date: getYYYYMMDD(startDate),
        end_date: getYYYYMMDD(endDate),
        breakdown: 'page',
        query: `~^${query.replace(/\s/g, '\\s?')}$`,
    });
    return `${base}?${search.toString()}`;
};
