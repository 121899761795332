import { FC, CSSProperties } from 'react';

import { createClassNames, createClassNamesGenerator, cx } from '@@styles';
import { MeasureOption } from '@@models';
import { getDateTimeStr } from '@@utils';

const c = createClassNames({
    root: {
        '&:not(:last-child)': {
            marginBottom: 4,
        },
    },
    userName: {
        fontSize: 12,
    },
    createdAt: {
        fontSize: 12,
    },
    text: {
        fontSize: 12,
    },
});

const f = createClassNamesGenerator({});

export const MeasureCard: FC<{
    className?: string;
    style?: CSSProperties;
    measureOption: MeasureOption;
    onClickEdit: () => void;
}> = ({ className, style, measureOption, onClickEdit }) => {
    // DATA
    const { measure, userOption } = measureOption;

    return (
        <div
            className={cx(c.root, className)}
            style={{
                background: userOption.user.color.getCssValue({ opacity: 0.2 }),
                ...style,
            }}
        >
            <span
                className={c.userName}
                style={{
                    color: userOption.user.color.getCssValue({ opacity: 1 }),
                    // background: userOption.user.color.getCssValue({ opacity: 0.3 }),
                }}
            >
                {userOption.user.displayName || userOption.user.uniqueName}
            </span>
            <span className={c.createdAt}>
                {getDateTimeStr({
                    date: measure.createdAt,
                    isOnlyTimeIfToday: false,
                    isNoYearForThisYear: true,
                })}
            </span>
            <span className={c.text}>{measure.text}</span>
            <button onClick={onClickEdit}>{'編'}</button>
        </div>
    );
};
MeasureCard.displayName =
    '@@construction/WholePage/Authorized/KeywordsTable/ChunkRow/KeywordRow/MeasuresPart/MeasureCard';
