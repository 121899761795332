import { FC } from 'react';

const Svg: FC<{ className?: string }> = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 1226 1226" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1201.48 626.903C1201.48 583.436 1197.58 541.64 1190.33 501.517H613V738.636H942.905C928.695 815.261 885.506 880.183 820.584 923.651V1077.46H1018.69C1134.61 970.74 1201.48 813.589 1201.48 626.903Z"
                fill="#4285F4"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M613 1226C778.51 1226 917.271 1171.11 1018.69 1077.49L820.584 923.678C765.693 960.458 695.477 982.192 613 982.192C453.342 982.192 318.203 874.36 269.999 729.469H65.2012V888.292C166.068 1088.63 373.373 1226 613 1226Z"
                fill="#34A853"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M269.999 729.46C257.739 692.68 250.773 653.392 250.773 612.99C250.773 572.588 257.739 533.3 269.999 496.52V337.697H65.2009C23.6841 420.452 0 514.074 0 612.99C0 711.906 23.6841 805.528 65.2009 888.283L269.999 729.46Z"
                fill="#FBBC05"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M613 243.807C703 243.807 783.804 274.735 847.333 335.478L1023.15 159.659C916.992 60.7427 778.232 0 613 0C373.373 0 166.068 137.368 65.2012 337.707L269.999 496.53C318.203 351.639 453.342 243.807 613 243.807Z"
                fill="#EA4335"
            />
        </svg>
    );
};
Svg.displayName = 'svg/google-g';
export default Svg;
