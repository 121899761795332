import { getAuth } from 'firebase/auth';

import { FirebaseProjectName } from '../firebaseConfig';
import { getFirebaseApp } from '../getFirebaseApp';

export const getFirebaseAuth = ({ projectName }: { projectName: FirebaseProjectName }) => {
    const app = getFirebaseApp({ projectName });
    const auth = getAuth(app);
    return auth;
};
