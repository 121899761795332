import { getRdbRef } from '@@firebase';
import { serializeNoteIdentifier } from '@@models';

// REF
export const getRdbNotesRef = ({ domain }: { domain: string }) => {
    return getRdbRef({
        projectName: 'default',
        path: `notes/${domain}`,
    });
};
export const getRdbNoteRef = ({
    domain,
    keywordText,
    userId,
}: {
    domain: string;
    keywordText: string;
    userId: string;
}) => {
    return getRdbRef({
        projectName: 'default',
        path: `notes/${domain}/${serializeNoteIdentifier({ keywordText, userId })}`,
    });
};

// TYPE
export type RdbNote<_TUndefined extends undefined | null = undefined, TDate extends Date | number = Date> = {
    // KNOWN: domain, keywordText, userId,

    // SYSTEM
    createdAt: TDate;
    updatedAt: TDate;

    // DATA
    text: string;
};

export const getRdbNote = ({ createdAt, updatedAt, text }: RdbNote<undefined, Date>): RdbNote<undefined, Date> => {
    return {
        createdAt,
        updatedAt,
        text,
    };
};

export const getRdbNoteInput = ({ createdAt, updatedAt, text }: RdbNote<undefined, Date>): RdbNote<null, number> => {
    return {
        createdAt: createdAt.getTime(),
        updatedAt: updatedAt.getTime(),
        text,
    };
};

export const getRdbNoteFromSource = ({
    createdAt,
    updatedAt,
    text,
}: RdbNote<undefined, number>): RdbNote<undefined, Date> => {
    return {
        createdAt: new Date(createdAt),
        updatedAt: new Date(updatedAt),
        text,
    };
};
