export class AssociativeModelBase<
    TItem,
    TKey extends ID = ID,
    TData extends {
        [key in TKey]: TItem;
    } = {
        [key in TKey]: TItem;
    }
> {
    data: TData;

    constructor(items: TData) {
        this.data = items;
    }

    get keys() {
        return Object.keys(this.data) as TKey[];
    }

    get(key: TKey): TItem | undefined {
        return this.data[key];
    }

    forEach(callback: (key: TKey, item: TItem) => void) {
        this.keys.forEach((key) => {
            const item = this.data[key]!;
            callback(key, item);
        });
    }

    map<TReturn>(callback: (key: TKey, item: TItem) => TReturn) {
        return this.keys.map((key) => {
            const item = this.data[key]!;
            return callback(key, item);
        });
    }

    assMap<TReturn>(callback: (key: TKey, item: TItem) => TReturn) {
        return new AssociativeModelBase(
            this.keys.reduce(
                (acc, key) => {
                    const item = this.data[key]!;
                    const newItem = callback(key, item);
                    return {
                        ...acc,
                        [key]: newItem,
                    };
                },
                {} as {
                    [key in TKey]: TReturn;
                }
            )
        );
    }

    find(callback: (key: TKey, item: TItem) => boolean) {
        const targetKey = this.keys.find((key) => {
            const item = this.data[key]!;
            return callback(key, item);
        });
        return targetKey ? this.data[targetKey] : undefined;
    }

    filter(callback: (key: TKey, item: TItem) => boolean) {
        return new AssociativeModelBase(
            this.keys.reduce((acc, key) => {
                const item = this.data[key]!;
                const isRemain = callback(key, item);
                if (isRemain) {
                    return {
                        ...acc,
                        [key]: item,
                    };
                }
                return acc;
            }, {} as typeof this.data)
        );
    }

    filterMap(callback: (key: TKey, item: TItem) => boolean) {
        return this.keys.reduce((acc, key) => {
            const item = this.data[key]!;
            const isRemain = callback(key, item);
            if (isRemain) {
                return [...acc, item];
            }
            return acc;
        }, [] as TItem[]);
    }

    filterWithRest(
        callback: (key: TKey, item: TItem) => boolean
    ): [AssociativeModelBase<TItem>, AssociativeModelBase<TItem>] {
        type TReturn = [typeof this.data, typeof this.data];
        const newData = this.keys.reduce(
            (acc, key) => {
                const item = this.data[key]!;
                const isRemain = callback(key, item);
                if (isRemain) {
                    return [
                        {
                            ...acc[0],
                            [key]: item,
                        },
                        acc[1],
                    ] as TReturn;
                }
                return [
                    acc[0],
                    {
                        ...acc[1],
                        [key]: item,
                    },
                ] as TReturn;
            },
            [{}, {}] as TReturn
        );
        return [new AssociativeModelBase(newData[0]), new AssociativeModelBase(newData[1])];
    }

    reduce<TReturn>(callback: (acc: TReturn, key: TKey, item: TItem) => TReturn, initialValue: TReturn) {
        return this.keys.reduce((acc, key) => {
            const item = this.data[key]!;
            return callback(acc, key, item);
        }, initialValue);
    }

    remove(key: TKey) {
        delete this.data[key];
    }

    update(key: TKey, callback: (item: TData[TKey] | undefined) => TData[TKey] | undefined) {
        const item = this.data[key];
        const updatedItem = callback(item ? { ...item } : undefined);
        if (updatedItem) {
            this.data[key] = updatedItem;
        }
    }
}
