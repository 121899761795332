import { createContext, useContext } from 'react';

import { Keywords } from '@@models';

type KeywordsCtxType = {
    keywordsOver100: Loading<Keywords>;
    keywordsUnder100: Loading<Keywords>;
};

export const KeywordsCtx = createContext<KeywordsCtxType>({
    keywordsOver100: 'loading',
    keywordsUnder100: 'loading',
});

export const useKeywordsCtx = () => {
    return useContext(KeywordsCtx);
};

export const useKeywordsCtxAbsolutely = () => {
    const { keywordsOver100, keywordsUnder100 } = useKeywordsCtx();
    if (keywordsOver100 === 'loading' || keywordsUnder100 === 'loading') {
        throw new Error('useKeywordsCtxAbsolutely: keywords === loading.');
    }
    return {
        keywordsOver100,
        keywordsUnder100,
    };
};
