import { FC, CSSProperties } from 'react';

import { createClassNames, cx, createClassNamesGenerator } from '@@styles';
import { ScOffset, ScTerm, getTermDatesText } from '@@models';
import { useLocationCtx } from '@@ctx';

const c = createClassNames({
    root: {
        fontSize: 12,
        marginTop: 14,
    },
    row: {
        'display': 'flex',
        'justifyContent': 'space-between',
        '&:not(:last-child)': {
            marginBottom: 7,
        },
        'position': 'relative',
    },
    termCell: {
        flexGrow: 0,
        flexShrink: 0,
        textAlign: 'right',
    },
    values: {
        display: 'flex',
        flexGrow: 0,
        flexShrink: 0,
    },
    valueCell: {
        flexGrow: 0,
        flexShrink: 0,
        textAlign: 'right',
    },
});

const f = createClassNamesGenerator({
    valueCell: ({ isBold }: { isBold: boolean }) => ({
        flexGrow: 0,
        flexShrink: 0,
        textAlign: 'right',
        fontWeight: isBold ? 600 : undefined,
    }),
});

const ValueCell: FC<{
    style?: CSSProperties;
    transitions: {
        [term in ScTerm]?: {
            [offset in ScOffset]?: number;
        };
    };
    term: ScTerm;
    offset: ScOffset;
    fixer: (value: number) => string;
    best: number;
    isBoldForCurrentTerm?: boolean;
}> = ({ style, transitions, term, offset, fixer, best, isBoldForCurrentTerm }) => {
    // CTX
    const { term: currentTerm } = useLocationCtx();

    // DATA
    const value = (() => {
        const offsets = transitions[term];
        if (offsets) {
            return offsets[offset];
        }
        return undefined;
    })();
    return (
        <div
            className={f.valueCell({
                isBold: isBoldForCurrentTerm ? currentTerm === term : best === value,
            })}
            style={style}
        >
            {value !== undefined ? fixer(value) : 'na'}
        </div>
    );
};

export const TransitionView: FC<{
    className?: string;
    style?: CSSProperties;
    termStyle?: CSSProperties;
    valueStyle?: CSSProperties;
    transitions: {
        [term in ScTerm]?: {
            [offset in ScOffset]?: number;
        };
    };
    fixer: (value: number) => string;
    best: number;
}> = ({ className, style, termStyle, valueStyle, transitions, fixer, best }) => {
    return (
        <div className={cx(c.root, className)} style={style}>
            <div className={c.row}>
                <div className={c.termCell} style={termStyle}>
                    {getTermDatesText({ term: '1w', offset: 0 })}
                </div>
                <ValueCell
                    style={valueStyle}
                    transitions={transitions}
                    term={'12w'}
                    offset={0}
                    fixer={fixer}
                    best={best}
                    isBoldForCurrentTerm
                />
                <ValueCell
                    style={valueStyle}
                    transitions={transitions}
                    term={'4w'}
                    offset={0}
                    fixer={fixer}
                    best={best}
                    isBoldForCurrentTerm
                />
                <ValueCell
                    style={valueStyle}
                    transitions={transitions}
                    term={'1w'}
                    offset={0}
                    fixer={fixer}
                    best={best}
                    isBoldForCurrentTerm
                />
            </div>
            <div className={c.row}>
                <div className={c.termCell} style={termStyle}>
                    {getTermDatesText({ term: '1w', offset: 1 })}
                </div>
                <div className={c.valueCell} style={valueStyle} />
                <div className={c.valueCell} style={valueStyle} />
                <ValueCell
                    style={valueStyle}
                    transitions={transitions}
                    term={'1w'}
                    offset={1}
                    fixer={fixer}
                    best={best}
                />
            </div>
            <div className={c.row}>
                <div className={c.termCell} style={termStyle}>
                    {getTermDatesText({ term: '1w', offset: 2 })}
                </div>
                <div className={c.valueCell} style={valueStyle} />
                <div className={c.valueCell} style={valueStyle} />
                <ValueCell
                    style={valueStyle}
                    transitions={transitions}
                    term={'1w'}
                    offset={2}
                    fixer={fixer}
                    best={best}
                />
            </div>
            <div className={c.row}>
                <div className={c.termCell} style={termStyle}>
                    {getTermDatesText({ term: '1w', offset: 3 })}
                </div>
                <div className={c.valueCell} style={valueStyle} />
                <div className={c.valueCell} style={valueStyle} />
                <ValueCell
                    style={valueStyle}
                    transitions={transitions}
                    term={'1w'}
                    offset={3}
                    fixer={fixer}
                    best={best}
                />
            </div>
            <div className={c.row}>
                <div className={c.termCell} style={termStyle}>
                    {getTermDatesText({ term: '4w', offset: 1 })}
                </div>
                <div className={c.valueCell} style={valueStyle} />
                <ValueCell
                    style={valueStyle}
                    transitions={transitions}
                    term={'4w'}
                    offset={1}
                    fixer={fixer}
                    best={best}
                />
                <div className={c.valueCell} style={valueStyle}>
                    {'-'}
                </div>
            </div>
            <div className={c.row}>
                <div className={c.termCell} style={termStyle}>
                    {getTermDatesText({ term: '4w', offset: 2 })}
                </div>
                <div className={c.valueCell} style={valueStyle} />
                <ValueCell
                    style={valueStyle}
                    transitions={transitions}
                    term={'4w'}
                    offset={2}
                    fixer={fixer}
                    best={best}
                />
                <div className={c.valueCell} style={valueStyle}>
                    {'-'}
                </div>
            </div>
            <div className={c.row}>
                <div className={c.termCell} style={termStyle}>
                    {getTermDatesText({ term: '4w', offset: 3 })}
                </div>
                <div className={c.valueCell} style={valueStyle} />
                <ValueCell
                    style={valueStyle}
                    transitions={transitions}
                    term={'4w'}
                    offset={3}
                    fixer={fixer}
                    best={best}
                />
                <div className={c.valueCell} style={valueStyle}>
                    {'-'}
                </div>
            </div>
            <div className={c.row}>
                <div className={c.termCell} style={termStyle}>
                    {getTermDatesText({ term: '12w', offset: 1 })}
                </div>
                <ValueCell
                    style={valueStyle}
                    transitions={transitions}
                    term={'12w'}
                    offset={1}
                    fixer={fixer}
                    best={best}
                />
                <div className={c.valueCell} style={valueStyle}>
                    {'-'}
                </div>
                <div className={c.valueCell} style={valueStyle}>
                    {'-'}
                </div>
            </div>
            <div className={c.row}>
                <div className={c.termCell} style={termStyle}>
                    {getTermDatesText({ term: '12w', offset: 2 })}
                </div>
                <ValueCell
                    style={valueStyle}
                    transitions={transitions}
                    term={'12w'}
                    offset={2}
                    fixer={fixer}
                    best={best}
                />
                <div className={c.valueCell} style={valueStyle}>
                    {'-'}
                </div>
                <div className={c.valueCell} style={valueStyle}>
                    {'-'}
                </div>
            </div>
            <div className={c.row}>
                <div className={c.termCell} style={termStyle}>
                    {getTermDatesText({ term: '12w', offset: 3 })}
                </div>
                <ValueCell
                    style={valueStyle}
                    transitions={transitions}
                    term={'12w'}
                    offset={3}
                    fixer={fixer}
                    best={best}
                />
                <div className={c.valueCell} style={valueStyle}>
                    {'-'}
                </div>
                <div className={c.valueCell} style={valueStyle}>
                    {'-'}
                </div>
            </div>
        </div>
    );
};
TransitionView.displayName = '@@construction/WholePage/Authorized/KeywordsTable/ChunkRow/KeywordRow/TransitionView';
