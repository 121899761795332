import { createContext, useContext } from 'react';

type DomainCtxType = {
    domain: string | undefined;
};

export const DomainCtx = createContext<DomainCtxType>({
    domain: undefined,
});

export const useDomainCtx = () => {
    return useContext(DomainCtx);
};

export const useDomainCtxAbsolutely = () => {
    const { domain } = useDomainCtx();
    if (!domain) {
        throw new Error('useDomainCtxAbsolutely: !domain.');
    }
    return {
        domain,
    };
};
