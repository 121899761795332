import { createContext, useContext } from 'react';

import { Pins } from '@@models';

type PinsCtxType = {
    pins: Loading<Pins>;
};

export const PinsCtx = createContext<PinsCtxType>({
    pins: 'loading',
});

export const usePinsCtx = () => {
    return useContext(PinsCtx);
};

export const usePinsCtxAbsolutely = () => {
    const { pins } = usePinsCtx();
    if (pins === 'loading') {
        throw new Error('usePinsCtxAbsolutely: pins === loading.');
    }
    return {
        pins,
    };
};
