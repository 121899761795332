import { getRdbRef } from '@@firebase';
import { serializePinIdentifier } from '@@models';

// REF
export const getRdbPinsRef = ({ domain }: { domain: string }) => {
    return getRdbRef({
        projectName: 'default',
        path: `pins/${domain}`,
    });
};
export const getRdbPinRef = ({
    domain,
    keywordText,
    userId,
}: {
    domain: string;
    keywordText: string;
    userId: string;
}) => {
    return getRdbRef({
        projectName: 'default',
        path: `pins/${domain}/${serializePinIdentifier({ keywordText, userId })}`,
    });
};

// TYPE
export type RdbPin<_TUndefined extends undefined | null = undefined, TDate extends Date | number = Date> = {
    // KNOWN: domain, keywordText, userId,

    // SYSTEM
    createdAt: TDate;
};

export const getRdbPin = ({ createdAt }: RdbPin<undefined, Date>): RdbPin<undefined, Date> => {
    return {
        createdAt,
    };
};

export const getRdbPinInput = ({ createdAt }: RdbPin<undefined, Date>): RdbPin<null, number> => {
    return {
        createdAt: createdAt.getTime(),
    };
};

export const getRdbPinFromSource = ({ createdAt }: RdbPin<undefined, number>): RdbPin<undefined, Date> => {
    return {
        createdAt: new Date(createdAt),
    };
};
