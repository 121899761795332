import { set } from 'firebase/database';

import { RdbNote, getRdbNoteInput, getRdbNoteRef } from './RdbNote';

export const addRdbNote = ({
    domain,
    keywordText,
    userId,
    text,
}: Omit<RdbNote<undefined, Date>, 'createdAt' | 'updatedAt'> & {
    domain: string;
    keywordText: string;
    userId: string;
}) => {
    const noteRef = getRdbNoteRef({ domain, keywordText, userId });
    set(
        noteRef,
        getRdbNoteInput({
            createdAt: new Date(),
            updatedAt: new Date(),
            text,
        })
    );
};
