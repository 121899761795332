import { update } from 'firebase/database';

import { getRdbNoteRef } from './RdbNote';

export const modifyRdbNoteText = ({
    domain,
    keywordText,
    userId,
    text,
}: {
    domain: string;
    keywordText: string;
    userId: string;
    text: string;
}) => {
    const ref = getRdbNoteRef({ domain, keywordText, userId });
    update(ref, {
        updatedAt: new Date(),
        text,
    });
};
