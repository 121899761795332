import { Measures, Measure } from '@@models';

import { setMeasures } from './setMeasures';

export const setMeasure = ({
    prev,
    key,
    update,
}: {
    prev: Measures;
    key: string;
    update: (prev: Measure | undefined) => Measure;
}): Measures => {
    return setMeasures({
        prev,
        update: (prevMeasures) => {
            if (prevMeasures === 'loading') {
                throw new Error('setMeasure: prevMeasures === loading.');
            }
            prevMeasures.update(key, update);
            return prevMeasures;
        },
    });
};
