import { FC, CSSProperties } from 'react';
import MuiCheckBox from '@mui/material/Checkbox';

import { createClassNames, cx } from '@@styles';
import { KeywordOption, serializePinIdentifier } from '@@models';
import { useOptionsCtxAbsolutely, useDomainCtxAbsolutely } from '@@ctx';
import { addRdbPin, removeRdbPin } from '@@Rdb';

const c = createClassNames({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

export const PinPart: FC<{
    className?: string;
    style?: CSSProperties;
    keywordOption: KeywordOption;
}> = ({ className, style, keywordOption }) => {
    // CTX
    const { userOption } = useOptionsCtxAbsolutely();
    const { domain } = useDomainCtxAbsolutely();

    return (
        <div className={cx(c.root, className)} style={style}>
            <MuiCheckBox
                checked={
                    !!keywordOption.pins.data[
                        serializePinIdentifier({
                            keywordText: keywordOption.keyword.query,
                            userId: userOption.user.id,
                        })
                    ]
                }
                onClick={() => {
                    if (
                        keywordOption.pins.data[
                            serializePinIdentifier({
                                keywordText: keywordOption.keyword.query,
                                userId: userOption.user.id,
                            })
                        ]
                    ) {
                        removeRdbPin({
                            domain,
                            keywordText: keywordOption.keyword.query,
                            userId: userOption.user.id,
                        });
                    } else {
                        addRdbPin({ domain, keywordText: keywordOption.keyword.query, userId: userOption.user.id });
                    }
                }}
            />
        </div>
    );
};
PinPart.displayName = '@@construction/WholePage/Authorized/KeywordsTable/ChunkRow/KeywordRow/PinPart';
