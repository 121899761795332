import { Notes, Note } from '@@models';

import { setNotes } from './setNotes';

export const setNote = ({
    prev,
    key,
    update,
}: {
    prev: Notes;
    key: string;
    update: (prev: Note | undefined) => Note;
}): Notes => {
    return setNotes({
        prev,
        update: (prevNotes) => {
            if (prevNotes === 'loading') {
                throw new Error('setNote: prevNotes === loading.');
            }
            prevNotes.update(key, update);
            return prevNotes;
        },
    });
};
