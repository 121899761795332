import { useReducer } from 'react';

import type { Notes, Note } from '@@models';

import { removeNote } from './removeNote';
import { setNote } from './setNote';
import { setNotes } from './setNotes';

export type DispatchNotesInput = Partial<{
    setNotes: {
        update: (prev: Loading<Notes>) => Notes;
    };
    setNote: {
        key: string;
        update: (prev: Note | undefined) => Note;
    };
    removeNote: {
        key: string;
    };
}>;
export type DispatchNotes = (input: DispatchNotesInput) => void;

export const useNotes = () => {
    // STATE
    const [notes, dispatchNotes] = useReducer<(prev: Loading<Notes>, input: DispatchNotesInput) => Loading<Notes>>(
        (prev, input) => {
            try {
                if (input.setNotes) {
                    return setNotes({
                        prev,
                        ...input.setNotes,
                    });
                }
                if (prev === 'loading') {
                    throw new Error('useNotes: prev === loading.');
                }
                if (input.setNote) {
                    return setNote({
                        prev,
                        ...input.setNote,
                    });
                }
                if (input.removeNote) {
                    return removeNote({
                        prev,
                        ...input.removeNote,
                    });
                }
            } catch {
                return prev;
            }
            throw new Error('useNotes: Unexpected.');
        },
        'loading'
    );

    return {
        notes,
        dispatchNotes,
    };
};
