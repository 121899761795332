// import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { LocationProvider, FirebaseUserCtxProvider } from '@@ctx';
import { WholePage } from '@@construction';

const container = document.getElementById('root');
if (!container) {
    throw new Error('index: !container.');
}
const root = createRoot(container);
// root.render(
//     <StrictMode>
//         <ReactAppRoot />
//     </StrictMode>
// );
// StrictModeでは２回レンダーされる。つまり、onMountで外部APIを２回叩く。
root.render(
    <LocationProvider>
        <FirebaseUserCtxProvider>
            <WholePage />
        </FirebaseUserCtxProvider>
    </LocationProvider>
);
