import { update } from 'firebase/database';

import { getRdbMeasureRef } from './RdbMeasure';

export const modifyRdbMeasureText = ({ domain, id, text }: { domain: string; id: string; text: string }) => {
    const ref = getRdbMeasureRef({ domain, id });
    update(ref, {
        updatedAt: new Date(),
        text,
    });
};
