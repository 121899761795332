import { getWithRetry } from '@@firebase';
import { User, Users } from '@@models';

import { getRdbDataMap } from '../utils';
import { getRdbUsersRef, getRdbUserFromSource, RdbUser } from '../RdbModels';

export const getUsers = async (): Promise<Users> => {
    const usersRef = getRdbUsersRef();
    const data = await getWithRetry(usersRef);
    const users = getRdbDataMap(data, (key, props: RdbUser<undefined, number>) => {
        return new User({
            ...getRdbUserFromSource(props),
            id: key,
        });
    });
    return new Users(users);
};
