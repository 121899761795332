import { set } from 'firebase/database';

import { RdbPin, getRdbPinInput, getRdbPinRef } from './RdbPin';

export const addRdbPin = ({
    domain,
    keywordText,
    userId,
}: Omit<RdbPin<undefined, Date>, 'createdAt' | 'updatedAt'> & {
    domain: string;
    keywordText: string;
    userId: string;
}) => {
    const pinRef = getRdbPinRef({ domain, keywordText, userId });
    set(
        pinRef,
        getRdbPinInput({
            createdAt: new Date(),
        })
    );
};
