import { createContext, useContext } from 'react';

import { ScQueryResults } from '@@models';

type ScQueryResultsCtxType = {
    scQueryResults: Loading<ScQueryResults>;
};

export const ScQueryResultsCtx = createContext<ScQueryResultsCtxType>({
    scQueryResults: 'loading',
});

export const useScQueryResultsCtx = () => {
    return useContext(ScQueryResultsCtx);
};

export const useScQueryResultsCtxAbsolutely = () => {
    const { scQueryResults } = useScQueryResultsCtx();
    if (scQueryResults === 'loading') {
        throw new Error('useScQueryResultsCtxAbsolutely: scQueryResults === loading.');
    }
    return {
        scQueryResults,
    };
};
