import { FC, ReactNode, useState, useRef } from 'react';

import { createClassNames, cx, createClassNamesGenerator } from '@@styles';

import { Popover } from './Popover';

const c = createClassNames({
    root: {},
    paper: {
        backdropFilter: 'blur(40px)',
        background: 'rgba(0,0,0,.23)',
        boxShadow: '0 64px 64px -64px rgb(0 0 0 / 48%)',
        WebkitAppRegion: 'no-drag',
    },
});

const f = createClassNamesGenerator({
    item: ({ isSelected }: { isSelected?: boolean }) => ({
        'cursor': isSelected ? 'default' : 'pointer',
        // 'opacity': isSelected ? 0.3 : undefined,
        'fontWeight': isSelected ? 600 : undefined,
        '&:hover': {
            background: isSelected ? undefined : '#f6f6f6',
        },
        'padding': '7px 10px',
        'fontSize': 14,
    }),
});

export const ButtonWithMenu: FC<{
    className?: string;
    children: ReactNode;
    menuItems: {
        text: string;
        onClick: () => void;
        isSelected?: boolean;
    }[];
}> = ({ className, children, menuItems }) => {
    // REF
    const buttonRef = useRef<HTMLButtonElement | null>(null);

    // STATE
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <button ref={buttonRef} className={cx(c.root, className)} onClick={() => setIsOpen(true)}>
                {children}
            </button>
            <Popover
                anchorEl={buttonRef.current}
                className={c.paper}
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {menuItems.map(({ text, onClick, isSelected }) => (
                    <div
                        key={text}
                        className={f.item({ isSelected })}
                        onClick={() => {
                            if (!isSelected) {
                                onClick();
                                setIsOpen(false);
                            }
                        }}
                    >
                        {text}
                    </div>
                ))}
            </Popover>
        </>
    );
};
