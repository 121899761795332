import { getScResults } from '@@Cs';

import { DispatchScQueryResults } from '../useDomainData';

export const initScQueryResults = async ({
    domain,
    dispatchScQueryResults,
}: {
    domain: string;
    dispatchScQueryResults: DispatchScQueryResults;
}) => {
    const fetchDateToday = new Date();
    const results = await getScResults({ domain, fetchDate: fetchDateToday });
    dispatchScQueryResults({
        setScQueryResults: {
            update: () => results,
        },
    });

    return () => undefined;
};
