import { getDatabase } from 'firebase/database';

import { FirebaseProjectName } from '../firebaseConfig';
import { getFirebaseApp } from '../getFirebaseApp';

export const getFirebaseRealtimeDatabase = ({ projectName }: { projectName: FirebaseProjectName }) => {
    const app = getFirebaseApp({ projectName });
    const database = getDatabase(app);
    return database;
};
