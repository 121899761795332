export class RgbColor {
    r: number;
    g: number;
    b: number;

    constructor(r: number, g: number, b: number) {
        this.r = r;
        this.g = g;
        this.b = b;
    }

    getCssValue({ opacity }: { opacity: number }) {
        if (opacity === 1) {
            return `rgb(${this.r},${this.g},${this.b})`;
        }
        return `rgba(${this.r},${this.g},${this.b},${opacity})`;
    }
}

export type RgbColorType = {
    r: number;
    g: number;
    b: number;
};
