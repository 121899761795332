import { Users } from '@@models';
import { setUsers } from './setUsers';

export const removeUser = ({ prev, key }: { prev: Users; key: string }): Users => {
    return setUsers({
        prev,
        update: (prevUsers) => {
            if (prevUsers === 'loading') {
                throw new Error('removeUser: prevUsers === loading.');
            }
            const newUsers = new Users({ ...prev.data });
            newUsers.remove(key);
            return newUsers;
        },
    });
};
