import { onChildRemoved, onChildAdded, onChildChanged } from 'firebase/database';

import { PresenceState } from '@@models';

import { getRdbPresenceStatesRef, getRdbPresenceStateFromSource } from '../RdbModels';
import { getRdbData } from '../utils';

export const listenToPresenceStates = ({
    domain,
    onAdded,
    onChanged,
    onRemoved,
}: {
    domain: string;
    onAdded: (addedPresenceState: PresenceState) => void;
    onChanged: (changedPresenceState: PresenceState) => void;
    onRemoved: (removedUserId: string) => void;
}) => {
    const presenceStatesRef = getRdbPresenceStatesRef({ domain });

    const unsubscribeOnChildAdded = onChildAdded(presenceStatesRef, (data) => {
        const rdbPresenceState = getRdbData(data, getRdbPresenceStateFromSource);
        if (rdbPresenceState) {
            onAdded(new PresenceState({ ...rdbPresenceState, userId: rdbPresenceState.id }));
        }
    });
    const unsubscribeOnChildChanged = onChildChanged(presenceStatesRef, (data) => {
        const rdbPresenceState = getRdbData(data, getRdbPresenceStateFromSource);
        if (rdbPresenceState) {
            onChanged(new PresenceState({ ...rdbPresenceState, userId: rdbPresenceState.id }));
        }
    });
    const unsubscribeOnChildRemoved = onChildRemoved(presenceStatesRef, (data) => {
        if (data.key) {
            onRemoved(data.key);
        }
    });

    return {
        unsubscribeListenToPresenceStates: () => {
            unsubscribeOnChildAdded();
            unsubscribeOnChildChanged();
            unsubscribeOnChildRemoved();
        },
    };
};
