import { onChildRemoved, onChildAdded, onChildChanged } from 'firebase/database';

import { Note, parseNoteIdentifier } from '@@models';

import { getRdbNotesRef, getRdbNoteFromSource } from '../RdbModels';
import { getRdbData } from '../utils';

export const listenToNotes = ({
    domain,
    onAdded,
    onChanged,
    onRemoved,
}: {
    domain: string;
    onAdded: (addedNote: Note, key: string) => void;
    onChanged: (changedNote: Note, key: string) => void;
    onRemoved: (removedNoteKey: string) => void;
}) => {
    const notesRef = getRdbNotesRef({ domain });

    const unsubscribeOnChildAdded = onChildAdded(notesRef, (data) => {
        const rdbNote = getRdbData(data, getRdbNoteFromSource);
        if (rdbNote) {
            const { keywordText, userId } = parseNoteIdentifier({ noteIdentifier: rdbNote.id });
            const note = new Note({ ...rdbNote, keywordText, userId });
            onAdded(note, rdbNote.id);
        }
    });
    const unsubscribeOnChildChanged = onChildChanged(notesRef, (data) => {
        const rdbNote = getRdbData(data, getRdbNoteFromSource);
        if (rdbNote) {
            const { keywordText, userId } = parseNoteIdentifier({ noteIdentifier: rdbNote.id });
            const note = new Note({ ...rdbNote, keywordText, userId });
            onChanged(note, rdbNote.id);
        }
    });
    const unsubscribeOnChildRemoved = onChildRemoved(notesRef, (data) => {
        if (data.key) {
            onRemoved(data.key);
        }
    });

    return {
        unsubscribeListenToNotes: () => {
            unsubscribeOnChildAdded();
            unsubscribeOnChildChanged();
            unsubscribeOnChildRemoved();
        },
    };
};
