import { PresenceStates } from '@@models';
import { setPresenceStates } from './setPresenceStates';

export const removePresenceState = ({ prev, key }: { prev: PresenceStates; key: string }): PresenceStates => {
    return setPresenceStates({
        prev,
        update: (prevPresenceStates) => {
            if (prevPresenceStates === 'loading') {
                throw new Error('removePresenceState: prevPresenceStates === loading.');
            }
            const newPresenceStates = new PresenceStates({ ...prev.data });
            newPresenceStates.remove(key);
            return newPresenceStates;
        },
    });
};
