import { FC } from 'react';

import { createClassNames, cx } from '@@styles';
import { useLocationCtx } from '@@ctx';

import { tableCellWidths } from './tableCellWidths';

const c = createClassNames({
    root: {
        position: 'sticky',
        top: 50,
        // background: 'rgba(255,255,255,.9)',
        background: 'rgba(240,240,240,.9)',
        // color: '#fff',
        zIndex: 2,
        display: 'flex',
        fontSize: 12,
        borderBottom: 'solid 1px #ddd',
    },
    cell: {
        'boxSizing': 'border-box',
        'flexGrow': 0,
        'flexShrink': 0,
        'padding': 7,
        '&:not(:last-child)': {
            borderRight: 'solid 1px #ddd',
        },
    },
    sortingColumn: {
        // background: '#e5d66d',
        '&::after': {
            content: '"↓"',
            color: '#aaa',
            fontWeight: 600,
        },
    },
});

const p = createClassNames({
    pin: {},
    categories: {},
    query: {
        position: 'sticky',
        left: 0,
        background: 'rgba(240,240,240,.9)',
    },
    volume: {},
    page: {},
    position: {},
    ctr: {},
    score: {},
    clicks: {},
    impressions: {},
    losingVolume: {},
    coverage: {},
    priority: {},
    measures: {},
    notes: {},
});

export const TableHeader: FC = () => {
    // CTX
    const { sortBy } = useLocationCtx();

    return (
        <div className={c.root}>
            <div className={cx(c.cell, p.pin)} style={{ width: tableCellWidths.pin }}>
                {'ピン'}
            </div>
            <div className={cx(c.cell, p.categories)} style={{ width: tableCellWidths.categories }}>
                {'カテゴリ'}
            </div>
            <div className={cx(c.cell, p.volume)} style={{ width: tableCellWidths.volume }}>
                {'検索Vol'}
            </div>
            <div className={cx(c.cell, p.query)} style={{ width: tableCellWidths.query }}>
                {'検索ワード'}
            </div>
            <div className={cx(c.cell, p.page)} style={{ width: tableCellWidths.page }}>
                {'表示されるページ'}
            </div>
            <div
                className={cx(c.cell, p.position, {
                    [c.sortingColumn]: sortBy === 'position',
                })}
                style={{ width: tableCellWidths.position }}
            >
                {'順位'}
            </div>
            <div
                className={cx(c.cell, p.ctr, {
                    [c.sortingColumn]: sortBy === 'ctr',
                })}
                style={{ width: tableCellWidths.ctr }}
            >
                {'CTR'}
            </div>
            <div
                className={cx(c.cell, p.clicks, {
                    [c.sortingColumn]: sortBy === 'clicks',
                })}
                style={{ width: tableCellWidths.clicks }}
            >
                {'クリック数'}
            </div>
            <div
                className={cx(c.cell, p.impressions, {
                    [c.sortingColumn]: sortBy === 'impressions',
                })}
                style={{ width: tableCellWidths.impressions }}
            >
                {'表示回数'}
            </div>
            <div
                className={cx(c.cell, p.score, {
                    [c.sortingColumn]: sortBy === 'score',
                })}
                style={{ width: tableCellWidths.score }}
            >
                {'スコア'}
            </div>
            <div
                className={cx(c.cell, p.losingVolume, {
                    [c.sortingColumn]: sortBy === 'losingVolume',
                })}
                style={{ width: tableCellWidths.losingVolume }}
            >
                {'逃しているVol'}
            </div>
            {/* <div className={cx(c.cell, p.coverage)} style={{ width: tableCellWidths.coverage }}>
                {'獲得/Vol'}
            </div> */}
            {/* <div className={cx(c.cell, p.priority)} style={{ width: tableCellWidths.priority }}>
                {'対応優先度'}
            </div> */}
            <div className={cx(c.cell, p.measures)} style={{ width: tableCellWidths.measures }}>
                {'施策'}
            </div>
            <div className={cx(c.cell, p.notes)} style={{ width: tableCellWidths.notes }}>
                {'メモ'}
            </div>
        </div>
    );
};
TableHeader.displayName = '@@construction/WholePage/Authorized/KeywordsTable/TableHeader';
