export const getDateStr = ({ date, isNoYearForThisYear }: { date: Date; isNoYearForThisYear?: boolean }) => {
    const monthNumber = date.getMonth();
    const dateNumber = date.getDate();
    const yearNumber = date.getFullYear();
    const { termDays, isThisYear } = (() => {
        const today = new Date();
        const dateDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        const todayDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        return {
            termDays: Math.floor((todayDate.getTime() - dateDate.getTime()) / 86400000),
            isThisYear: today.getFullYear() === yearNumber,
        };
    })();
    if (termDays === 0) {
        return '今日';
    }
    if (termDays === 1) {
        return '昨日';
    }
    if (isNoYearForThisYear && isThisYear) {
        return `${String(monthNumber + 1)}/${String(dateNumber)}`;
    }
    return `${String(yearNumber)}/${String(monthNumber + 1)}/${String(dateNumber)}`;
};
export const getDateTimeStr = ({
    date,
    isOnlyTimeIfToday,
    isNoYearForThisYear,
}: {
    date: Date;
    isOnlyTimeIfToday?: boolean;
    isNoYearForThisYear?: boolean;
}) => {
    const hours = String(date.getHours());
    const minutes = (() => {
        const num = date.getMinutes();
        if (num < 10) {
            return `0${String(num)}`;
        }
        return String(num);
    })();
    const termDays = (() => {
        const today = new Date();
        const dateDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        const todayDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        return Math.floor((todayDate.getTime() - dateDate.getTime()) / 86400000);
    })();
    if (termDays === 0) {
        if (isOnlyTimeIfToday) {
            return `${hours}:${minutes}`;
        }
        return `今日 ${hours}:${minutes}`;
    }
    if (termDays === 1) {
        return `昨日 ${hours}:${minutes}`;
    }
    return `${getDateStr({ date, isNoYearForThisYear })} ${hours}:${minutes}`;
};
