export const firebaseConfig = {
    apiKey: 'AIzaSyA0Kjhe5GQXowULlWyQLXQndf71kOiTBTU',
    authDomain: 'keywordmanager-aed46.firebaseapp.com',
    projectId: 'keywordmanager-aed46',
    storageBucket: 'keywordmanager-aed46.appspot.com',
    messagingSenderId: '258137940235',
    appId: '1:258137940235:web:994cf12eb2d6291dd7c20e',
};

export type FirebaseProjectName = 'default';

export const firebaseDevVersion = '0';
