import { RgbColor, RgbColorType } from '../interfaces';

export class User {
    // SYSTEM
    readonly id: string;
    readonly createdAt: Date;
    updatedAt: Date;

    // DATA
    readonly uniqueName: string;
    readonly displayName: string | undefined;
    readonly color: RgbColor;

    constructor({
        id,
        createdAt,
        updatedAt,
        uniqueName,
        displayName,
        color,
    }: {
        // SYSTEM
        readonly id: string;
        readonly createdAt: Date;
        updatedAt: Date;

        // DATA
        readonly uniqueName: string;
        readonly displayName: string | undefined;
        readonly color: RgbColorType;
    }) {
        this.id = id;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.uniqueName = uniqueName;
        this.displayName = displayName;
        this.color = new RgbColor(color.r, color.g, color.b);
    }
}
