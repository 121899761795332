import { generateKeyPair, privateEncrypt, publicDecrypt, publicEncrypt, privateDecrypt } from 'crypto';

export const encryptPki = ({ raw, key, keyKind }: { raw: string; key: string; keyKind: 'public' | 'private' }) => {
    // console.log({ raw, key, keyKind });
    const encrypted =
        keyKind === 'private'
            ? privateEncrypt(key, Buffer.from(raw, 'utf8'))
            : publicEncrypt(key, Buffer.from(raw, 'utf8'));
    // console.log({ encrypted });
    return encrypted.toString('base64');
};

export const decryptPki = ({
    encrypted,
    key,
    keyKind,
}: {
    encrypted: string;
    key: string;
    keyKind: 'public' | 'private';
}) => {
    // console.log({ encrypted, publicKey });
    const decrypted =
        keyKind === 'private'
            ? privateDecrypt(key, Buffer.from(encrypted, 'base64'))
            : publicDecrypt(key, Buffer.from(encrypted, 'base64'));
    // console.log({ decrypted });
    return decrypted.toString('utf8');
};

export const createPair = () => {
    return new Promise<{
        publicKey: string;
        privateKey: string;
    }>((resolve, reject) => {
        generateKeyPair(
            'rsa',
            {
                modulusLength: 4096,
                publicKeyEncoding: {
                    type: 'spki',
                    format: 'pem',
                },
                privateKeyEncoding: {
                    type: 'pkcs8',
                    format: 'pem',
                    // cipher: 'aes-256-cbc',
                    // passphrase: '',
                },
            },
            (err, publicKey, privateKey) => {
                if (err) {
                    reject(err);
                }
                if (publicKey && privateKey) {
                    resolve({
                        publicKey,
                        privateKey,
                    });
                }
            }
        );
    });
};
