import { onDisconnect, remove } from 'firebase/database';

import { listenToConnection } from '@@firebase';

import { getRdbPresenceStateRef, setRdbPresenceState } from '../RdbModels';

export const enterSpace = ({
    userId,
    domain,
    getWindowIsFocused,
}: {
    userId: string;
    domain: string;
    getWindowIsFocused: () => Promise<boolean>;
}) => {
    const presenceStateRef = getRdbPresenceStateRef({ domain, userId });

    getWindowIsFocused().then((isWatching) => {
        setRdbPresenceState({
            domain,
            userId,
            isWatching,
        });
    });
    onDisconnect(presenceStateRef).remove();

    const { unsubscribeConnection } = listenToConnection({
        onChange: (isConnected) => {
            if (isConnected) {
                getWindowIsFocused().then((isWatching) => {
                    setRdbPresenceState({
                        domain,
                        userId,
                        isWatching,
                    });
                });
                onDisconnect(presenceStateRef).remove();
            }
        },
    });

    return {
        exitSpace: () => {
            unsubscribeConnection();
            remove(presenceStateRef);
        },
    };
};
