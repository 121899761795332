import { Measures } from '@@models';
import { setMeasures } from './setMeasures';

export const removeMeasure = ({ prev, key }: { prev: Measures; key: string }): Measures => {
    return setMeasures({
        prev,
        update: (prevMeasures) => {
            if (prevMeasures === 'loading') {
                throw new Error('removeMeasure: prevMeasures === loading.');
            }
            const newMeasures = new Measures({ ...prev.data });
            newMeasures.remove(key);
            return newMeasures;
        },
    });
};
