import { PresenceStates, PresenceState } from '@@models';

import { setPresenceStates } from './setPresenceStates';

export const setPresenceState = ({
    prev,
    key,
    update,
}: {
    prev: PresenceStates;
    key: string;
    update: (prev: PresenceState | undefined) => PresenceState;
}): PresenceStates => {
    return setPresenceStates({
        prev,
        update: (prevPresenceStates) => {
            if (prevPresenceStates === 'loading') {
                throw new Error('setPresenceState: prevPresenceStates === loading.');
            }
            prevPresenceStates.update(key, update);
            return prevPresenceStates;
        },
    });
};
