import { createContext, useContext } from 'react';

import { Category, ScTerm } from '@@models';

// 'priority' はまだ準備できていない
export type SortBy = 'losingVolume' | 'score' | 'clicks' | 'impressions' | 'position' | 'ctr';
export const sortBys = ['losingVolume', 'score', 'clicks', 'position', 'impressions', 'ctr'] as const;
export const isSortBy = (text: string): text is SortBy => {
    return sortBys.includes(text as SortBy);
};

type LocationCtxType = {
    search: string | undefined;
    sortBy: SortBy;
    filterByCategory: Category | undefined;
    filterByWord: string | undefined;
    filterByVolume: number | undefined;
    term: ScTerm;
    updateSearch: (input: { newSearch: string | undefined; isReplace: boolean }) => void;
    updateSortBy: (input: SortBy) => void;
    updateFilterByCategory: (input: Category | undefined) => void;
    updateFilterByWord: (input: string | undefined) => void;
    updateFilterByVolume: (input: number | undefined) => void;
    updateTerm: (input: ScTerm) => void;
};
export const LocationCtx = createContext<LocationCtxType>({
    search: undefined,
    sortBy: 'losingVolume',
    filterByCategory: undefined,
    filterByWord: undefined,
    filterByVolume: undefined,
    term: '1w',
    updateSearch: () => {
        throw new Error('LocationCtx.updateSearch: not initialized.');
    },
    updateSortBy: () => {
        throw new Error('LocationCtx.updateSortBy: not initialized.');
    },
    updateFilterByCategory: () => {
        throw new Error('LocationCtx.updateFilterByCategory: not initialized.');
    },
    updateFilterByWord: () => {
        throw new Error('LocationCtx.updateFilterByWord: not initialized.');
    },
    updateFilterByVolume: () => {
        throw new Error('LocationCtx.updateFilterByVolume: not initialized.');
    },
    updateTerm: () => {
        throw new Error('LocationCtx.updateTerm: not initialized.');
    },
});
export const useLocationCtx = () => {
    return useContext(LocationCtx);
};
