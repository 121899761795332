import { useReducer } from 'react';

import type { Users, User } from '@@models';

import { removeUser } from './removeUser';
import { setUser } from './setUser';
import { setUsers } from './setUsers';

export type DispatchUsersInput = Partial<{
    setUsers: {
        update: (prev: Loading<Users>) => Users;
    };
    setUser: {
        key: string;
        update: (prev: User | undefined) => User;
    };
    removeUser: {
        key: string;
    };
}>;
export type DispatchUsers = (input: DispatchUsersInput) => void;

export const useUsers = () => {
    // STATE
    const [users, dispatchUsers] = useReducer<(prev: Loading<Users>, input: DispatchUsersInput) => Loading<Users>>(
        (prev, input) => {
            try {
                if (input.setUsers) {
                    return setUsers({
                        prev,
                        ...input.setUsers,
                    });
                }
                if (prev === 'loading') {
                    throw new Error('useUsers: prev === loading.');
                }
                if (input.setUser) {
                    return setUser({
                        prev,
                        ...input.setUser,
                    });
                }
                if (input.removeUser) {
                    return removeUser({
                        prev,
                        ...input.removeUser,
                    });
                }
            } catch {
                return prev;
            }
            throw new Error('useUsers: Unexpected.');
        },
        'loading'
    );

    return {
        users,
        dispatchUsers,
    };
};
