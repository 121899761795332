import type { ScTerm, ScOffset } from './ScQueryResult';

export const serializeScQueryResultIdentifier = ({ term, offset }: { term: ScTerm; offset: number }): string => {
    return `${term}-${offset}`;
};

export const parseScQueryResultIdentifier = ({
    scQueryResultIdentifier,
}: {
    scQueryResultIdentifier: string;
}): Errorable<{
    term: ScTerm;
    offset: ScOffset;
}> => {
    const match = scQueryResultIdentifier.match(/^([^:]+)::(.+)$/);
    if (match) {
        const [_, term, offset_] = match;
        const offset = Number(offset_);
        if (term && !isNaN(Number(offset)) && [0, 1, 2, 3].includes(offset)) {
            return {
                term: term as ScTerm,
                offset: offset as ScOffset,
            };
        }
    }
    throw new Error(`parseScQueryResultIdentifier: failed to parse ${scQueryResultIdentifier}.`);
};
