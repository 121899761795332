import type { ScQueryResultItem, ScTerm, ScOffset } from '../interfaces';

export class ScQueryResult {
    // SYSTEM
    readonly createdAt: Date;

    // DATA
    readonly term: ScTerm;
    readonly fetchDate: Date;
    readonly offset: ScOffset;
    readonly results: {
        [keywordText: string]: {
            primary: ScQueryResultItem;
            others: ScQueryResultItem[];
        };
    };

    constructor({
        createdAt,
        term,
        fetchDate,
        offset,
        results,
    }: {
        // SYSTEM
        readonly createdAt: Date;

        // DATA
        readonly term: ScTerm;
        readonly fetchDate: Date;
        readonly offset: ScOffset;
        readonly results: {
            [keywordText: string]: {
                primary: ScQueryResultItem;
                others: ScQueryResultItem[];
            };
        };
    }) {
        this.createdAt = createdAt;
        this.term = term;
        this.fetchDate = fetchDate;
        this.offset = offset;
        this.results = results;
    }
}
