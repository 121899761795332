import { createContext, useContext } from 'react';

import { Notes } from '@@models';

type NotesCtxType = {
    notes: Loading<Notes>;
};

export const NotesCtx = createContext<NotesCtxType>({
    notes: 'loading',
});

export const useNotesCtx = () => {
    return useContext(NotesCtx);
};

export const useNotesCtxAbsolutely = () => {
    const { notes } = useNotesCtx();
    if (notes === 'loading') {
        throw new Error('useNotesCtxAbsolutely: notes === loading.');
    }
    return {
        notes,
    };
};
