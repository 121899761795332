import { createContext, useContext } from 'react';

import { Users, User } from '@@models';

type UsersCtxType = {
    users: Loading<Users>;
};

export const UsersCtx = createContext<UsersCtxType>({
    users: 'loading',
});

export const useUsersCtx = () => {
    return useContext(UsersCtx);
};

export const useUsersCtxAbsolutely = () => {
    const { users } = useUsersCtx();
    if (users === 'loading') {
        throw new Error('useUsersCtxAbsolutely: users === loading.');
    }
    return {
        users,
    };
};
