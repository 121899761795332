import { getMeasures, listenToMeasures } from '@@Rdb';
import { DispatchMeasures } from '../useDomainData';

export const initMeasures = async ({
    domain,
    dispatchMeasures,
}: {
    domain: string;
    dispatchMeasures: DispatchMeasures;
}) => {
    const measures = await getMeasures({ domain });
    dispatchMeasures({
        setMeasures: {
            update: () => measures,
        },
    });

    const { unsubscribeListenToMeasures } = listenToMeasures({
        domain,
        onAdded: (addedMeasure, key) => {
            dispatchMeasures({
                setMeasure: {
                    key,
                    update: () => addedMeasure,
                },
            });
        },
        onChanged: (changedMeasure, key) => {
            dispatchMeasures({
                setMeasure: {
                    key,
                    update: () => changedMeasure,
                },
            });
        },
        onRemoved: (removedMeasureId) => {
            dispatchMeasures({
                removeMeasure: {
                    key: removedMeasureId,
                },
            });
        },
    });

    return unsubscribeListenToMeasures;
};
