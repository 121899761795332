import { getBlob } from 'firebase/storage';

import { FirebaseProjectName } from '../firebaseConfig';
import { getCsRef } from './getCsRef';

export const downloadBlobFromCloudStorage = async ({
    projectName,
    path,
}: {
    projectName: FirebaseProjectName;
    path: string;
}) => {
    const storageRef = getCsRef({ projectName, path });
    const blob = await getBlob(storageRef);
    return blob;
};
