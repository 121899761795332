import { useCallback } from 'react';

import { useUnsubscribers } from '@@utils';
import {
    DispatchMeasures,
    DispatchNotes,
    DispatchPins,
    DispatchPresenceStates,
    DispatchScQueryResults,
    DispatchUsers,
} from '../useDomainData';

import { initMeasures } from './initMeasures';
import { initNotes } from './initNotes';
import { initPins } from './initPins';
import { initPresenceStates } from './initPresenceStates';
import { initScQueryResults } from './initScQueryResults';
import { initUsers } from './initUsers';

export const useInitDomainData = ({
    dispatchMeasures,
    dispatchNotes,
    dispatchPins,
    dispatchPresenceStates,
    dispatchScQueryResults,
    dispatchUsers,
}: {
    dispatchMeasures: DispatchMeasures;
    dispatchNotes: DispatchNotes;
    dispatchPins: DispatchPins;
    dispatchPresenceStates: DispatchPresenceStates;
    dispatchScQueryResults: DispatchScQueryResults;
    dispatchUsers: DispatchUsers;
}) => {
    // STATE
    const { unsubscribe, setUnsubscriber } = useUnsubscribers();

    // CALLBACK
    const initDomainData = useCallback(async ({ domain }: { domain: string }) => {
        unsubscribe({ id: domain });

        const unsubscribers = await Promise.all([
            initMeasures({ domain, dispatchMeasures }),
            initNotes({ domain, dispatchNotes }),
            initPins({ domain, dispatchPins }),
            initPresenceStates({ domain, dispatchPresenceStates }),
            initScQueryResults({ domain, dispatchScQueryResults }),
            initUsers({ dispatchUsers }),
        ]);
        setUnsubscriber({
            id: domain,
            unsubscriber: () => {
                unsubscribers.forEach((unsubscriber) => unsubscriber());
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        initDomainData,
    };
};
