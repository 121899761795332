import { MeasureOption } from '../Options/MeasureOption';
import { AssociativeModelBase } from './_AssociativeModelBase';

export class MeasureOptions extends AssociativeModelBase<MeasureOption> {
    getArrByCreatedAt() {
        return this.map((_, item) => item).sort((a_, b_) => {
            const a = a_.measure.createdAt;
            const b = b_.measure.createdAt;
            if (a > b) {
                return -1;
            }
            return 1;
        });
    }
}
