import { Notes } from '@@models';
import { setNotes } from './setNotes';

export const removeNote = ({ prev, key }: { prev: Notes; key: string }): Notes => {
    return setNotes({
        prev,
        update: (prevNotes) => {
            if (prevNotes === 'loading') {
                throw new Error('removeNote: prevNotes === loading.');
            }
            const newNotes = new Notes({ ...prev.data });
            newNotes.remove(key);
            return newNotes;
        },
    });
};
