type EnvVariables = {
    ENVIRONMENT: string;
    PKI_PUBLIC_KEY: string;
    GOOGLE_CLIENT_ID: string;
    OAUTH_REDIRECT_URI: string;
};

export const getEnvVariables = (): EnvVariables => {
    return {
        ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT || '',
        PKI_PUBLIC_KEY: (process.env.REACT_APP_PKI_PUBLIC_KEY || '').replace(/\\n/g, '\n'),
        GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID || '',
        OAUTH_REDIRECT_URI: process.env.REACT_APP_OAUTH_REDIRECT_URI || '',
    };
};
