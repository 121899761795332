import { getStorage } from 'firebase/storage';

import { FirebaseProjectName } from '../firebaseConfig';
import { getFirebaseApp } from '../getFirebaseApp';

export const getFirebaseStorage = ({ projectName }: { projectName: FirebaseProjectName }) => {
    const app = getFirebaseApp({ projectName });
    const storage = getStorage(app);
    return storage;
};
