import { useReducer } from 'react';

import type { PresenceStates, PresenceState } from '@@models';

import { removePresenceState } from './removePresenceState';
import { setPresenceState } from './setPresenceState';
import { setPresenceStates } from './setPresenceStates';

export type DispatchPresenceStatesInput = Partial<{
    setPresenceStates: {
        update: (prev: Loading<PresenceStates>) => PresenceStates;
    };
    setPresenceState: {
        key: string;
        update: (prev: PresenceState | undefined) => PresenceState;
    };
    removePresenceState: {
        key: string;
    };
}>;
export type DispatchPresenceStates = (input: DispatchPresenceStatesInput) => void;

export const usePresenceStates = () => {
    // STATE
    const [presenceStates, dispatchPresenceStates] = useReducer<
        (prev: Loading<PresenceStates>, input: DispatchPresenceStatesInput) => Loading<PresenceStates>
    >((prev, input) => {
        try {
            if (input.setPresenceStates) {
                return setPresenceStates({
                    prev,
                    ...input.setPresenceStates,
                });
            }
            if (prev === 'loading') {
                throw new Error('usePresenceStates: prev === loading.');
            }
            if (input.setPresenceState) {
                return setPresenceState({
                    prev,
                    ...input.setPresenceState,
                });
            }
            if (input.removePresenceState) {
                return removePresenceState({
                    prev,
                    ...input.removePresenceState,
                });
            }
        } catch {
            return prev;
        }
        throw new Error('usePresenceStates: Unexpected.');
    }, 'loading');

    return {
        presenceStates,
        dispatchPresenceStates,
    };
};
